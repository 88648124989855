import { Grid, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useStateIfMounted } from "use-state-if-mounted";
import BasketContext from "../../store/basket-context";
import useFormatHelper from "../../useFormatHelper";
import BasketCounter from "../BasketCounter/BasketCounter";
import "./SaleUnitItem.css";

function SaleUnitItem(props) {
  const { basketContext } = useContext(BasketContext);
  const { formatMoney } = useFormatHelper();
  const { t } = useTranslation();

  const product = props.product;
  const salesUnit = props.salesUnit;

  const basketItemDiscount = basketContext.items.filter(
    (f) => f.productId === product.id && f.price !== f.defaultPrice
  )[0];

  let unitPrice = basketItemDiscount
    ? basketItemDiscount.salesUnitItemUnitPrice
    : salesUnit.defaultUnitPrice;
  if (isNaN(unitPrice)) {
    unitPrice = "-,";
  } else {
    unitPrice = formatMoney(unitPrice);
  }

  let price = basketItemDiscount
    ? basketItemDiscount.salesUnitItemUnitPrice * salesUnit.piece
    : salesUnit.defaultPrice;
  if (isNaN(price)) {
    price = "-,";
  } else {
    price = formatMoney(price);
  }

  const [count, setCount] = useStateIfMounted(0);

  useEffect(() => {
    setCount(basketContext.getItem(product.id, salesUnit.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basketContext, product.id, salesUnit.id]);

  return (
    <Grid
      container
      columns={{ xs: 1, sm: 2 }}
      key={"saleUnit_" + salesUnit.id}
      sx={{ display: "flex", alignItems: "center" }}
    >
      <div className="rowProd">
        <div className="first">
          <Typography variant="body1">
            {salesUnit.unitName} ({salesUnit.piece} {t("stk")})
          </Typography>
          <div className="unit">
            {t("unit_price2")}: €{unitPrice}
          </div>
        </div>
        <div className="second">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            >
              <Typography
                variant="bigPrice"
                sx={{
                  fontWeight: 600,
                }}
              >
                &nbsp;&nbsp;{price}&nbsp;€
              </Typography>
            </div>
            {basketItemDiscount && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: (theme) => theme.palette.grey[500],
                      textDecoration: "line-through",
                    }}
                  >
                    {formatMoney(salesUnit.defaultUnitPrice)}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    &nbsp;€&nbsp;&nbsp;
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      color: (theme) => theme.palette.grey[500],
                      fontWeight: 600,
                      textDecoration: "line-through",
                    }}
                  >
                    {formatMoney(salesUnit.defaultPrice)}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color: (theme) => theme.palette.grey[500],
                      fontWeight: 600,
                    }}
                  >
                    &nbsp;€
                  </Typography>
                </div>
              </div>
            )}
          </div>
          <BasketCounter
            readonly={false}
            productId={product.id}
            productName={product.name}
            productUnit={product.unit}
            imageUrl={product.image}
            salesUnitItemId={salesUnit.id}
            salesUnitItemUnitName={salesUnit.unitName}
            salesUnitItemPiece={salesUnit.piece}
            salesUnitItemUnitPrice={salesUnit.unitPrice}
            price={salesUnit.price}
            count={count}
          />
        </div>
      </div>
    </Grid>
  );
}

export default SaleUnitItem;
