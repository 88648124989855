import { useNavigate } from "react-router-dom";
import useFormatHelper from "../../../useFormatHelper";
import { orderService } from "../../../services/control/orderService";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const ControlItem = (props) => {
    const orderItem = props?.orderItem;

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { formatDateTime, formatMoney } = useFormatHelper();
    const { t } = useTranslation();
    
    let color = "today"

    if (orderItem) {
        if (orderItem?.statusId === 1) {
            color = "parking";
        } else if (orderItem?.statusId === 2) {
            color = "ready";
        }
    }

    const printOrder = (orderId) => {
        orderService
        .orderPdf(orderId, null)
        .then((response) => {
          var byteCharacters = atob(response.base64PdfData);
          var byteNumbers = new Array(byteCharacters.length);
          for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          var byteArray = new Uint8Array(byteNumbers);
          var blob = new Blob([byteArray], { type: "application/pdf" });
  
          var blobUrl = URL.createObjectURL(blob);
  
          window.open(blobUrl, "_blank");
        })
        .catch((error) => {
          enqueueSnackbar(error, {
            variant: "error",
          });
        });
    }

    return (
        <div
            className="control-item"
            onClick={() => {
                navigate("/apps/control/control-item/" + orderItem?.orderId);
            }}
        >
            <div className={`control-item-header ${color}`}>
                {orderItem?.customerName}
            </div>
            <div className="control-item-body">
                <div className="control-item-content">
                    <p>
                        <span>{t('order_no')} </span>{orderItem?.orderId}
                    </p>
                    <p>
                        <span>{t('order-date')}:</span> {formatDateTime(orderItem?.orderDate)}
                    </p>
                </div>
                <div className="control-item-footer">
                    <div onClick={(e) => {
                        e.stopPropagation();
                        printOrder(orderItem?.orderId)
                    }}>
                        <i className="ri-printer-line" />
                    </div>
                    <div>
                        {formatMoney(orderItem?.totalPrice)} €
                    </div>    
                </div>
            </div>
        </div>
    )
}

export default ControlItem;