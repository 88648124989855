/* eslint-disable eqeqeq */
import { Box, CardMedia, Typography } from "@mui/material";
import React, { useContext } from "react";
// import { useTranslation } from "react-i18next";
import { config } from "../../services/config";
import { useTranslation } from "react-i18next";
import useFormatHelper from "../../useFormatHelper";
import BasketCounter from "../BasketCounter/BasketCounter";
import BasketContext from "../../store/basket-context";
import "./BasketProductItem.css";

function BasketProductItem(props) {
  const { t } = useTranslation();
  const { basketContext } = useContext(BasketContext);
  const { formatMoney } = useFormatHelper();
  const basketItem = props.basketItem;
  const productName = basketItem.productName;
  //const productUnit = basketItem.productUnit;
  const imageUrl = config.baseOrigin + basketItem.imageUrl;
  const salesUnitItemUnitName = basketItem.salesUnitItemUnitName;
  const salesUnitItemPiece = basketItem.salesUnitItemPiece;
  const limit = props.limit;

  const previousOrder = props.previousOrder ?? false;

  const shortTitle = (productName, limit) => {
    if (productName.length > limit) {
      let words = productName.split(" ");
      let newProductName = "";
      let nameLength = 0;

      // Kelimeleri ekleyerek toplam uzunluğu kontrol et
      for (let i = 0; i < words.length; i++) {
        if (nameLength + words[i].length <= limit) {
          newProductName += words[i] + " ";
          nameLength += words[i].length + 1; // 1 eklemek boşluğu temsil eder
        } else {
          break;
        }
      }

      newProductName = newProductName.trim() + "...";

      return newProductName;
    } else {
      return productName;
    }
  };

  const basketItemDiscount =
    basketItem.price != basketItem.defaultPrice ? basketItem : null;

  let unitPrice = basketItemDiscount
    ? basketItemDiscount.salesUnitItemUnitPrice
    : basketItem.defaultUnitPrice;

  if (isNaN(unitPrice)) {
    unitPrice = "-,";
  } else {
    unitPrice = formatMoney(unitPrice);
  }

  let price =
    basketItemDiscount != null
      ? basketItemDiscount.price
      : basketItem.defaultPrice;

  if (typeof price == "string") {
    price = parseFloat(price.replace(",", "."));
  }

  if (isNaN(price)) {
    price = "-,";
  } else {
    price = formatMoney(price);
  }

  let basketItemPrice = 0;

  if (typeof basketItem.price == "string") {
    basketItemPrice = parseFloat(basketItem.price.replace(",", "."));
  } else {
    basketItemPrice = basketItem.price;
  }

  //const { t } = useTranslation();

  return (
    productName != null && (
      <div className="shopping-card">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%;",
            }}
          >
            <Box
              className="image-block"
              sx={{
                marginLeft: "10px",
              }}
            >
              <CardMedia
                component="img"
                height="100%"
                width="100%"
                image={imageUrl}
                sx={{
                  objectFit: "contain",
                }}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = "/no-photo.png";
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                marginLeft: "11px",
                mr: 1,
                width: "100%;",
              }}
            >
              <div className="prod-title">
                {basketItemDiscount && (
                  <span className="dx-dx">
                    %{" "}
                    {formatMoney(
                      ((basketItem.salesUnitItemPiece *
                        basketItem.salesUnitItemUnitPrice -
                        basketItem.defaultPrice) /
                        basketItem.defaultPrice) *
                        100
                    )}
                  </span>
                )}
                <Typography
                  component="div"
                  variant="subtitle1"
                  color="text.secondary"
                  sx={{
                    textAlign: "left",
                    fontWeight: "bold",
                  }}
                >
                  {shortTitle(productName, limit)}
                </Typography>
              </div>
              <div className="prod-bot">
                <div className="prod-flex">
                  <div className="prices">
                    <div
                      className={
                        basketItemDiscount == null
                          ? "top old"
                          : "old decoration"
                      }
                    >
                      {basketItemDiscount &&
                        formatMoney(basketItemDiscount.defaultPrice) + "€"}
                    </div>

                    <div className="new">{price}€</div>
                  </div>
                  <BasketCounter
                    readonly={props.readonly}
                    productId={basketItem.productId}
                    productName={basketItem.productName}
                    productUnit={basketItem.productUnit}
                    imageUrl={basketItem.imageUrl}
                    salesUnitItemId={basketItem.salesUnitItemId}
                    salesUnitItemUnitName={basketItem.salesUnitItemUnitName}
                    salesUnitItemPiece={basketItem.salesUnitItemPiece}
                    salesUnitItemUnitPrice={basketItem.salesUnitItemUnitPrice}
                    price={basketItem.price}
                    count={basketItem.count}
                    home={true}
                    previousOrder={previousOrder}
                  />
                </div>
                {props.readonly === false && (
                  <div
                    className="remove-product"
                    onClick={() => {
                      basketContext.setItem(
                        basketItem.tax,
                        basketItem.productId,
                        basketItem.productName,
                        basketItem.productUnit,
                        basketItem.imageUrl,
                        basketItem.salesUnitItemId,
                        basketItem.salesUnitItemUnitName,
                        basketItem.salesUnitItemPiece,
                        basketItem.salesUnitItemUnitPrice,
                        basketItem.price,
                        0
                      );
                    }}
                  >
                    <i className="ri-delete-bin-line" />
                    <span>{t("delete")}</span>
                  </div>
                )}
              </div>
            </Box>
          </Box>
        </Box>
        <div className="bottom">
          <div>
            {salesUnitItemUnitName} ({salesUnitItemPiece})
          </div>
          <div>
            {t("number")}: {salesUnitItemPiece * basketItem.count}
          </div>
          <div className={basketItemDiscount ? "bold red" : "bold"}>
            {price === "-,"
              ? price
              : formatMoney(basketItemPrice * basketItem.count)}
            €
          </div>
        </div>
      </div>
    )
  );
}

export default BasketProductItem;
