import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, Box, TextField } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { config } from "../../../services/config";
import "./ProductItemDialog.css";

const ProductItemDialog = (props) => {
  const { orderItem, setDialogOpen, dialogOpen, approveProduct, setAutoFocus } = props;
  const baseOrigin = config.baseOrigin;
  const defaultImg = "/no-photo.png";

  const calculateMax = () => orderItem.numberOfItems - (orderItem.numberOfApprovedItems !== undefined ? orderItem.numberOfApprovedItems : 0);

  const [max, setMax] = useState(calculateMax());
  const [count, setCount] = useState(max);

  useEffect(() => {
    const newMax = calculateMax();
    setMax(newMax);
    setCount(newMax);
  }, [orderItem]);

  const handleCount = (value) => {
    const numericValue = parseInt(value, 10);
    if (!isNaN(numericValue) && numericValue > 0) {
      setCount(numericValue);
    } else {
      setCount(0);
    }
  }

  const handleClose = () => {
    setAutoFocus(true);
    setDialogOpen(false);
  };

  const handleClick = () => {
    approveProduct(orderItem, count);
    handleClose();
  }

  const add = () => {
    if (count < max) {
      setCount(count + 1);
    }
  }

  const minus = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={dialogOpen}
      onClose={handleClose}
    >
      <DialogTitle className="control-dialog-title">
        AKTARMA
      </DialogTitle>
      <DialogContent dividers>
        <Box>
          <div className="control-dialog-product-info">
            <div className="control-image">
              <img
                src={orderItem.productImage ? baseOrigin + orderItem.productImage : defaultImg}
                alt={orderItem.productName}
                style={{ width: '350px', height: '350px', objectFit: 'contain' }}
              />
            </div>
            <div className="dialog-control-right">
              <span>{orderItem.productName} ({orderItem.salesUnitName})</span>
              <div className="control-dialog-product-top">
                Aktarılacak Sayısı:
                <TextField value={count} onChange={(e) => handleCount(e.target.value)} sx={{ width: "100px" }} />
                <div className="control-count-buttons">
                  <button onClick={minus}><Remove /></button>
                  <button onClick={add}><Add /></button>
                </div>
              </div>
              <div className="control-dialog-bottom">
                <button onClick={handleClose}>İptal</button>
                <button className="close" onClick={handleClick}>Aktar</button>
              </div>
            </div>
          </div>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default ProductItemDialog;
