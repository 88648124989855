import {
  AppBar,
  IconButton,
  InputBase,
  List,
  SwipeableDrawer,
  Toolbar,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStateIfMounted } from "use-state-if-mounted";
import { config } from "../../services/config";
import ProductContext from "../../store/product-context";
import LanguageSelect from "../LanguageSelect/LanguageSelect";
import Logo from "../Logo/Logo";
import Profile from "../Profile/Profile";
import Sidebar from "../Sidebar/Sidebar";
import "./Header.css";
import { useNavigate } from "react-router-dom";

let searchTimeout;

function Header(props) {
  const navigate = useNavigate();

  const { productContext } = useContext(ProductContext);
  const [hasChanged, setHasChanged] = useStateIfMounted(true);
  const [search, setSearch] = useStateIfMounted("");
  const [openCategory, setOpenCategory] = useState(false);
  const [openUserMenu, setOpenUserMenu] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const { t } = useTranslation();
  let imageUrl = "/no-photo.png";
  if (props.logo != null) {
    imageUrl = config.baseOrigin + props.logo;
  }

  function searchTextHandler(text) {
    setSearch(text);
    if (text.length > 0) {
      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(() => {
        productContext.setProductState("search", text);
      }, 750);
    } else {
      clearTimeout(searchTimeout);
      if (productContext.getProductState("search") !== undefined) {
        setHasChanged(false);
        productContext.setProductState("search", undefined);
      }
    }
  }

  useEffect(() => {
    const text = productContext.getProductState("search");
    if (hasChanged) {
      setSearch(text || "");
    } else {
      setHasChanged(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productContext]);

  const toggleCagetoryDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenCategory(open);
  };
  const toggleUserMenuDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenUserMenu(open);
  };
  const toggleSearchDrawer = (open) => (event) => {
    if (event && event.type === "keydown") {
      if (event.key === "Escape") {
        setOpenSearch(false);
      }
      return;
    } else {
      setOpenSearch(open);
    }
  };

  return (
    <header className="mainHeader">
      <div className="header-container">
        <div className="appBar">
          <div className="logo">
            <Logo imageUrl={imageUrl} />
          </div>

          <Box className="search">
            <Box>
              <div className="search-button">
                <i className="ri-search-line" />
              </div>
              <InputBase
                sx={{ pl: 2, flex: 1, color: "#fff" }}
                placeholder={t("search")}
                value={search}
                onChange={(event) => {
                  searchTextHandler(event.target.value);
                }}
              />
            </Box>
          </Box>

          <div className="language">
            <LanguageSelect />
          </div>

          <div className="profile">
            <Profile mobile={false} />
          </div>
        </div>
      </div>
      <AppBar
        position="static"
        sx={{
          display: { xs: "flex" },
          justifyContent: "center",
          height: "88px",
          background: "#F9FAFB",
          color: "#000",
          borderBottom: "1px solid #E9EBF1",
          boxShadow: "initial",
        }}
        className="mobile-appbar"
      >
        <Toolbar
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className="m-h-left">
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              sx={{ mr: 2, zIndex: 100, fontSize: "2rem" }}
              onClick={toggleCagetoryDrawer(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M3 4H21V6H3V4ZM3 11H15V13H3V11ZM3 18H21V20H3V18Z"
                  fill="black"
                />
              </svg>
              <span className="menu-nav">MENU</span>
            </IconButton>
          </div>
          <Logo
            imageUrl={imageUrl}
            style={{
              width: 140,
              height: 45,
              display: "flex",
              alignItems: "center",
            }}
          />
          <div className="m-h-right">
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              sx={{ zIndex: 100, fontSize: "2rem" }}
              onClick={toggleSearchDrawer(true)}
            >
              <i className="ri-search-line head-icon" />
            </IconButton>
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              sx={{ zIndex: 100, fontSize: "2rem" }}
              onClick={() => {
                navigate("/basket");
              }}
            >
              <i className="ri-shopping-cart-line head-icon" />
            </IconButton>
            <Profile mobile={true} />
          </div>
        </Toolbar>
        <SwipeableDrawer
          anchor="left"
          open={openCategory}
          onClose={toggleCagetoryDrawer(false)}
          onOpen={toggleCagetoryDrawer(true)}
        >
          <Box
            sx={{ width: 310 }}
            onClick={toggleCagetoryDrawer(false)}
            onKeyDown={toggleCagetoryDrawer(false)}
          >
            <div className="m-big-menu">
              <div className="m-menu-title">
                <span>Menu</span>
                <div className="m-menu-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      d="M7.0007 5.5865L11.9504 0.636719L13.3646 2.05093L8.4149 7.0007L13.3646 11.9504L11.9504 13.3646L7.0007 8.4149L2.05093 13.3646L0.636719 11.9504L5.5865 7.0007L0.636719 2.05093L2.05093 0.636719L7.0007 5.5865Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </div>
              <div className="m-language">
                <LanguageSelect />
              </div>
            </div>
            <Sidebar storeURLs={props.storeURLs} width={250} />
          </Box>
        </SwipeableDrawer>
        <SwipeableDrawer
          anchor="right"
          open={openUserMenu}
          onClose={toggleUserMenuDrawer(false)}
          onOpen={toggleUserMenuDrawer(true)}
        >
          <Box
            sx={{ width: 250 }}
            onClick={toggleUserMenuDrawer(false)}
            onKeyDown={toggleUserMenuDrawer(false)}
          >
            <List component="nav">
              <Profile mobile={true} />
            </List>
          </Box>
        </SwipeableDrawer>
        <SwipeableDrawer
          anchor="top"
          open={openSearch}
          onClose={toggleSearchDrawer(false)}
          onOpen={toggleSearchDrawer(true)}
        >
          <Box
            sx={{ height: 50, display: "flex" }}
            onKeyDown={toggleSearchDrawer(false)}
            className="m-search"
          >
            <IconButton sx={{ pl: "15px" }} color="primary">
              <i className="ri-search-line head-icon" />
            </IconButton>
            <InputBase
              placeholder={t("search")}
              value={search}
              onChange={(event) => {
                searchTextHandler(event.target.value);
              }}
            />
          </Box>
        </SwipeableDrawer>
      </AppBar>
    </header>
  );
}

export default Header;
