import { baseService } from "./baseService.js";
import { config } from "./config";

export const accountService = {
  getAccount,
};

function getAccount() {
  return baseService.post(config.endpoints.customerInfo);
}
