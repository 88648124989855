import { config } from "./config";

export const baseService = {
  get,
  post,
};

function getToken() {
  const userInfo = JSON.parse(localStorage.getItem("user"));
  if (userInfo) return userInfo.token;
  return "";
}

function getLanguage() {
  const language = localStorage.getItem("i18nextLng");
  if (language) return language;
  return null;
}

function get(url) {
  const token = getToken();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "Set-Cookie": "cross-site-cookie=whatever; SameSite=Lax;",
    },
  };

  let requestUrl = getLanguageWithUrl(url);

  return fetch(requestUrl, requestOptions).then(handleResponse);
}

function post(url, body, signal) {
  const token = getToken();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "Set-Cookie": "cross-site-cookie=whatever; SameSite=Lax;",
    },
    body: JSON.stringify(body),
    ...signal,
  };

  let requestUrl = getLanguageWithUrl(url);

  return fetch(requestUrl, requestOptions).then(handleResponse);
}

function getLanguageWithUrl(url) {
  const language = getLanguage();
  const pages = [
    "/configuration/aboutUs",
    "/configuration/contact",
    "/configuration/impressum",
    "/configuration/agb",
  ];
  let requestUrl = "";

  if (pages.includes(url)) {
    requestUrl = `${config.otherApiUrl}${url}`;
  } else {
    requestUrl = `${config.apiUrl}${url}`;
  }

  if (language) {
    if (requestUrl.indexOf("?") > 0) {
      requestUrl += `&lang=${language}`;
    } else {
      requestUrl += "/";
      if (requestUrl[requestUrl.length - 1] === "/") {
        requestUrl = requestUrl.substring(0, requestUrl.length - 1);
      }
      requestUrl += `?lang=${language}`;
    }
  }
  return requestUrl;
}

function handleResponse(response) {
  return response.text().then((responseText) => {
    let data;
    try {
      data = responseText && JSON.parse(responseText);
      if (!response.ok) {
        if (response.status === 401) {
          localStorage.removeItem("user");
          window.location.reload();
        }
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
    } catch (ex) {
      return Promise.reject(ex);
    }

    return data;
  });
}
