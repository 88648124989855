import { Box, Grid, Tab, Tabs } from "@mui/material";
import React from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import ProductDetailImageView from "../ProductDetailImageView/ProductDetailImageView";
import SaleUnitItem from "../SaleUnitItem/SaleUnitItem";
import "./ProductItemDetail.css";
import MobilesaleUnitItem from "../SaleUnitItem/MobileSaleUnitItem";
import useFormatHelper from "../../useFormatHelper";
import { t } from "i18next";
import Favorite from "../Favorite/Favorite";

function ProductItemDetail(props) {
  const product = props.product;
  const [tabValue, setTabValue] = useStateIfMounted(0);
  const { formatDateByShortDate } = useFormatHelper();
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  let discountMessage;
  if (product.discount != null) {
    discountMessage = t("discountMessage");
    discountMessage = discountMessage.replace(
      "{startDate}",
      formatDateByShortDate(product.discount.startDate)
    );
    discountMessage = discountMessage.replace(
      "{endDate}",
      formatDateByShortDate(product.discount.endDate)
    );
    discountMessage = discountMessage.replace(
      "{piece}",
      product.discount.piece
    );
    discountMessage = discountMessage.replace(
      "{price}",
      product.discount.price
    );
  }

  return (
    <Grid
      container
      columns={{ xs: 1, md: 2 }}
      spacing={0.5}
      alignItems="stretch"
    >
      <Grid item xs={1} md={1} style={{ position: "relative" }}>
        <div
          className="dialog-fav"
          style={{ textAlign: "right", width: "93%" }}
        >
          <Favorite
            product={product}
            favorite={props.favorite}
            setFavorite={props.setFavorite}
          />
        </div>
        {product.images !== undefined && product.images.length > 0 && (
          <ProductDetailImageView
            images={product.images}
            productName={product.name}
            discount={product.discount}
          />
        )}
      </Grid>

      <Grid item xs={1} md={1} sx={{ display: "flex" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            ml: 2,
            mt: 1,
          }}
          className="res-dialog"
        >
          <Box
            sx={{
              mt: { xs: 2, md: 0 },
              mb: 3,
              textAlign: { xs: "center", sm: "inherit" },
            }}
          >
            <div className="brandName">
              <span>{t("brand")}: </span>
              <div> {product.brand.name}</div>
            </div>
            <div className="brandName">
              <span>
                {t("grammage")} / {t("weight")}:{" "}
              </span>
              <div> {product.unit}</div>
            </div>
          </Box>

          <Box className="pid-web">
            {product.salesUnits != null &&
              product.salesUnits.length > 0 &&
              product.salesUnits.map((salesUnit, i) => {
                return (
                  <Box key={"salesUnit_block_" + salesUnit.id} sx={{ pb: 1 }}>
                    <SaleUnitItem
                      key={"salesUnit_" + salesUnit.id}
                      salesUnit={salesUnit}
                      product={product}
                    />
                  </Box>
                );
              })}
            {product.discount && (
              <div
                style={{
                  textAlign: "center",
                  padding: "3px",
                  color: "white",
                  backgroundColor: "#E5322D",
                  borderRadius: "8px",
                  fontSize: "10px !important",
                  boxShadow: "0px 10px 20px 0px rgba(229, 50, 45, 0.20)",
                }}
              >
                {discountMessage}
              </div>
            )}
          </Box>
          <Box className="pid-mobile">
            <MobilesaleUnitItem product={product} />
          </Box>
        </Box>
      </Grid>
      {(product.shortDescription ||
        product.detailedDescription ||
        product.allergyInformation ||
        product.contentInformation) && (
        <div className="tab-nav">
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons={false}
          >
            {product.shortDescription && <Tab label={t("short_description")} />}
            {product.detailedDescription && (
              <Tab label={t("detailed_description")} />
            )}
            {product.allergyInformation && (
              <Tab label={t("allergy_information")} />
            )}
            {product.contentInformation && (
              <Tab label={t("content_information")} />
            )}
          </Tabs>
          <div className="panel">
            {tabValue === 0 && <div>{product.shortDescription}</div>}
            {tabValue === 1 && <div>{product.detailedDescription}</div>}
            {tabValue === 2 && <div>{product.allergyInformation}</div>}
            {tabValue === 3 && <div>{product.contentInformation}</div>}
          </div>
        </div>
      )}
    </Grid>
  );
}

export default ProductItemDetail;
