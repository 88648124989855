import { baseService } from "./baseService";
import { config } from "./config";

export const productService = {
  getProducts,
  getProductById,
  addFavorite,
  removeFavorite,
};

function getProducts(body, signal) {
  return baseService.post(config.endpoints.getProductsBySorted, body, signal);
}

function getProductById(productId) {
  return baseService.post(
    config.endpoints.getProductById.replace("{productId}", productId)
  );
}

function addFavorite(productId) {
  return baseService.post(
    config.endpoints.favorite
      .replace("{productId}", productId)
      .replace("{favorite}", true)
  );
}

function removeFavorite(productId) {
  return baseService.post(
    config.endpoints.favorite
      .replace("{productId}", productId)
      .replace("{favorite}", false)
  );
}
