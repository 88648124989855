import { Box } from "@mui/material";
import React from "react";
import useFormatHelper from "../../useFormatHelper";
import { useTranslation } from "react-i18next";
import "./InvoiceItem.css";
import { orderService } from "../../services/orderService";

function InvoiceItem(props) {
  const { t } = useTranslation();
  const orderItem = props.orderItem;
  const { formatDateTime, formatMoney } = useFormatHelper();

  const downloadPdf = (invoiceId) => {
    orderService.getPdf(invoiceId).then((response) => {
      var byteCharacters = atob(response.data);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var blob = new Blob([byteArray], { type: "application/pdf" });

      var blobUrl = URL.createObjectURL(blob);

      window.open(blobUrl, "_blank");
    });
  };
  return (
    orderItem != null && (
      <Box>
        <Box
          className="previous-item"
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
          }}
          onClick={() => {
            downloadPdf(orderItem.invoiceId);
          }}
        >
          <Box className="left-box">
            <div className="left-box-in">
              <div className="order-id">
                {t("invoice_no")}: #{orderItem.invoiceId}
              </div>
              <span className="date">
                {" "}
                {formatDateTime(orderItem.invoiceDate)}
              </span>
            </div>
          </Box>

          <Box className="right-box">
            <div className="invoice-big-price">
              {formatMoney(orderItem.totalPrice)}€
            </div>
            <small className="invoice-status">
              {orderItem.remainingAmount !== 0 && orderItem.paidTotal !== 0
                ? t("invoice_open") +
                  ": " +
                  formatMoney(orderItem.totalPrice) +
                  "€"
                : t("invoice_completed")}
            </small>
          </Box>
        </Box>
      </Box>
    )
  );
}

export default InvoiceItem;
