// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cc {
  margin-top: -18px;
}
.text-discount {
  color: #ff1d16 !important;
}
.bg-discount {
  color: #ff1d16 !important;
}
.xx-add {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 2px solid #151518;
  /*box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);*/
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 2px;
}
.add-container {
  display: flex;
  justify-content: flex-end;
}
.xx-bg-discount {
  border: 2px solid #ff1d16 !important;
  background-color: #ff1d16;
}
.xx-bg-discount i {
  color: #fff !important;
}
.bot-top .x-unit {
  font-size: 12px;
  font-family: "Satoshi-Regular";
}
.green-plus {
  background-color: #00da23 !important;
  color: #fff;
  border: 2px solid #00da23 !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/ProductBottom/ProductBottom.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;EACzB,qDAAqD;EACrD,uBAAuB;EACvB,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,aAAa;EACb,yBAAyB;AAC3B;AACA;EACE,oCAAoC;EACpC,yBAAyB;AAC3B;AACA;EACE,sBAAsB;AACxB;AACA;EACE,eAAe;EACf,8BAA8B;AAChC;AACA;EACE,oCAAoC;EACpC,WAAW;EACX,oCAAoC;AACtC","sourcesContent":[".cc {\r\n  margin-top: -18px;\r\n}\r\n.text-discount {\r\n  color: #ff1d16 !important;\r\n}\r\n.bg-discount {\r\n  color: #ff1d16 !important;\r\n}\r\n.xx-add {\r\n  width: 40px;\r\n  height: 40px;\r\n  border-radius: 20px;\r\n  border: 2px solid #151518;\r\n  /*box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);*/\r\n  justify-content: center;\r\n  display: flex;\r\n  align-items: center;\r\n  margin-top: 2px;\r\n}\r\n.add-container {\r\n  display: flex;\r\n  justify-content: flex-end;\r\n}\r\n.xx-bg-discount {\r\n  border: 2px solid #ff1d16 !important;\r\n  background-color: #ff1d16;\r\n}\r\n.xx-bg-discount i {\r\n  color: #fff !important;\r\n}\r\n.bot-top .x-unit {\r\n  font-size: 12px;\r\n  font-family: \"Satoshi-Regular\";\r\n}\r\n.green-plus {\r\n  background-color: #00da23 !important;\r\n  color: #fff;\r\n  border: 2px solid #00da23 !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
