import { AccountCircle, KeyboardArrowUp } from "@mui/icons-material";
import {
  Button,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useStateIfMounted } from "use-state-if-mounted";
import BasketContext from "../../store/basket-context";
import LoginContext from "../../store/login-context";
import ProductContext from "../../store/product-context";
import "./Profile.css";

function Profile(props) {
  const [user, setUser] = useStateIfMounted(null);
  const { productContext } = useContext(ProductContext);
  const { loginContext } = useContext(LoginContext);
  const { basketContext } = useContext(BasketContext);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setUser(loginContext.user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginContext.user]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      {!props.mobile && (
        <div className="user-process-block">
          {user === undefined || user === null ? (
            <>
              <div className="login">
                <div className="user-icon">
                  <i className="ri-user-3-line" />
                </div>
                <span
                  onClick={() => {
                    loginContext.setLoginState("open", true);
                    loginContext.setLoginState("tabValue", 1);
                  }}
                >
                  {t("sign_in")}
                </span>
                <span className="no-underline">{t("or")}</span>
                <span
                  onClick={() => {
                    loginContext.setLoginState("open", true);
                    loginContext.setLoginState("tabValue", 0);
                  }}
                >
                  {t("user_login")}
                </span>
              </div>
            </>
          ) : (
            <div className="authentication-info">
              <div className="username">
                <Button
                  sx={{
                    color: "#fff",
                    textTransform: "none",
                  }}
                  ref={anchorRef}
                  id="composition-button"
                  aria-controls={open ? "composition-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                >
                  <AccountCircle />
                  <Typography component="div" sx={{ m: 1, fontSize: "14px" }}>
                    {user.fullName}
                  </Typography>
                  {
                    <KeyboardArrowUp
                      className={open ? "profile-image open" : "profile-image"}
                    />
                  }
                </Button>
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps }) => (
                    <Grow {...TransitionProps}>
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            autoFocusItem={open}
                            id="composition-menu"
                            aria-labelledby="composition-button"
                            onKeyDown={handleListKeyDown}
                          >
                            <MenuItem
                              onClick={(event) => {
                                handleClose(event);
                                navigate("/profile");
                              }}
                            >
                              Profile
                            </MenuItem>
                            <MenuItem
                              onClick={(event) => {
                                handleClose(event);
                                navigate("/favourites");
                                productContext.setProductState(
                                  "favorite",
                                  true
                                );
                              }}
                            >
                              {t("favorite_products")}
                            </MenuItem>
                            <MenuItem
                              onClick={(event) => {
                                handleClose(event);
                                navigate("/previous-orders");
                              }}
                            >
                              {t("my_previous_orders")}
                            </MenuItem>
                            <MenuItem
                              onClick={(event) => {
                                handleClose(event);
                                navigate("/invoices");
                              }}
                            >
                              {t("my_invoice")}
                            </MenuItem>

                            <MenuItem
                              onClick={(event) => {
                                handleClose(event);
                                navigate("/payments");
                              }}
                            >
                              {t("my_payments")}
                            </MenuItem>

                            <MenuItem
                              onClick={() => {
                                loginContext.setLoginState("user", null);
                                basketContext.setItems([]);
                                basketContext.clearPrices();
                                if (
                                  productContext.getProductState("favorite")
                                ) {
                                  productContext.setProductState(
                                    "favorite",
                                    false
                                  );
                                }
                                productContext.setProductState("page", 0);
                              }}
                            >
                              {t("logout")}
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            </div>
          )}
        </div>
      )}
      {props.mobile && (
        <>
          {user === undefined || user === null ? (
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              sx={{ zIndex: 100, fontSize: "2rem" }}
              onClick={() => {
                loginContext.setLoginState("open", true);
                loginContext.setLoginState("tabValue", 0);
              }}
            >
              <i className="ri-user-3-line head-icon" />
            </IconButton>
          ) : (
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              sx={{ zIndex: 100, fontSize: "2rem" }}
              onClick={() => {
                navigate("/profile");
              }}
            >
              <i className="ri-user-3-line head-icon" />
            </IconButton>
          )}
        </>
      )}
    </>
  );
}

export default Profile;
