import { Box, Grid, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { pageService } from "../../services/pageService";
import "./Page.css";

import { useParams } from "react-router-dom";

function Page() {
  let { pageId } = useParams();

  const [content, setContent] = useState();
  const [title, setTitle] = useState();

  useEffect(() => {
    pageService.getPage(pageId).then((response) => {
      let rescontent = response.text.replace(/\r\n/g, "<br>");

      setContent(rescontent);
      setTitle(response.title);
    });
  }, [pageId]);

  return (
    <Container>
      <Box sx={{ mb: 4, mt: 4 }}>
        <Grid
          container
          columns={{ xs: 1, md: 7 }}
          spacing={3}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <div className="page-title">{title}</div>
          <div className="page-body">
            <p dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </Grid>
      </Box>
    </Container>
  );
}

export default Page;
