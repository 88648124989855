import { baseService } from "./baseService";
import { config } from "./config";

export const countryService = {
  getCountries,
};

function getCountries() {
  return baseService.post(config.endpoints.getCountries);
}
