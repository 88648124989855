import React from "react";
import Header from "../Header/Header";

function HeaderWrapper(props) {
  return (
    <div
      className="headerWrapper"
      style={{
        position: "sticky",
        top: 0,
        zIndex: 100,
      }}
    >
      <Header logo={props.logo} storeURLs={props.storeURLs} />
    </div>
  );
}

export default HeaderWrapper;
