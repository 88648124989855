// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-block {
  width: 70px;
  height: 70px;
  border: 1px solid #f3f0fe;
  border-radius: 12px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.price-block {
  display: flex;
  align-items: center;
  background-color: #f3f0fe;
  border-radius: 8px;
  padding-right: 8px;
  padding-left: 8px;
}
.previous-product-count {
  display: block;
  font-size: 12px !important;
}
.previous-item .left-box {
  display: flex;
}
.previous-item .left-box .status {
  margin-top: -3px;
  margin-left: 15px;
  padding: 3px 13px;
  height: 25px;
  min-width: 110px;
  width: auto;
}
.previous-item {
  cursor: pointer;
}
.previous-item .right-box .invoice-status {
  font-size: 12px;
}
.previous-item .right-box .invoice-big-price {
  font-size: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/components/InvoiceItem/InvoiceItem.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,mBAAmB;EACnB,cAAc;EACd,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;AACnB;AACA;EACE,cAAc;EACd,0BAA0B;AAC5B;AACA;EACE,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;EACjB,YAAY;EACZ,gBAAgB;EAChB,WAAW;AACb;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB","sourcesContent":[".image-block {\r\n  width: 70px;\r\n  height: 70px;\r\n  border: 1px solid #f3f0fe;\r\n  border-radius: 12px;\r\n  flex-shrink: 0;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  align-content: center;\r\n}\r\n\r\n.price-block {\r\n  display: flex;\r\n  align-items: center;\r\n  background-color: #f3f0fe;\r\n  border-radius: 8px;\r\n  padding-right: 8px;\r\n  padding-left: 8px;\r\n}\r\n.previous-product-count {\r\n  display: block;\r\n  font-size: 12px !important;\r\n}\r\n.previous-item .left-box {\r\n  display: flex;\r\n}\r\n.previous-item .left-box .status {\r\n  margin-top: -3px;\r\n  margin-left: 15px;\r\n  padding: 3px 13px;\r\n  height: 25px;\r\n  min-width: 110px;\r\n  width: auto;\r\n}\r\n.previous-item {\r\n  cursor: pointer;\r\n}\r\n.previous-item .right-box .invoice-status {\r\n  font-size: 12px;\r\n}\r\n.previous-item .right-box .invoice-big-price {\r\n  font-size: 18px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
