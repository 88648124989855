import { baseService } from "./baseService.js";
import { config } from "./config";

export const advertisingService = {
  getAdvertisements,
};

function getAdvertisements() {
  return baseService.post(config.endpoints.getAdvertisements);
}
