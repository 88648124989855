import { Box } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import useFormatHelper from "../../useFormatHelper";
import { useTranslation } from "react-i18next";
import "./PreviousOrderItem.css";

function PreviousOrderItem(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const orderItem = props.orderItem;
  const { formatDateTime, textTrim } = useFormatHelper();
  return (
    orderItem != null && (
      <Box>
        <Box
          className="previous-item"
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box className="left-box">
            <div className="left-box-in">
              <div className="order-id">
                {t("order_no")} #{orderItem.id} ({orderItem.itemCount})
              </div>
              <span className="date">
                {" "}
                {formatDateTime(orderItem.orderDate)}
              </span>
            </div>
            <div className={textTrim(orderItem.statusDescription) + " status"}>
              {orderItem.statusDescription}
            </div>
          </Box>

          <Box className="right-box">
            <div
              className="orderDetail"
              onClick={() => {
                navigate("/previous-orders/" + orderItem.id);
              }}
            >
              {t("order-detail")} <i className="ri-arrow-right-s-line" />
            </div>
          </Box>
        </Box>
      </Box>
    )
  );
}

export default PreviousOrderItem;
