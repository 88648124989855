import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import { productService } from "../../services/productService";
import ProductItemDetail from "../ProductItemDetail/ProductItemDetail";
import "./ProductItemDialog.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ProductItemDialog(props) {
  const [saleUnitItemsCount, setSaleUnitItemsCount] = useStateIfMounted([]);
  const productId = props.productId;
  const [product, setProduct] = useStateIfMounted(null);

  useEffect(() => {
    if (props.dialogOpen) {
      setSaleUnitItemsCount([]);
      setProduct(null);

      productService.getProductById(productId).then((response) => {
        setProduct(response);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dialogOpen, productId]);

  const handleClose = () => {
    props.setDialogOpen(false);
  };

  const saleUnitItemsCountHandler = (salesUnitItem, count) => {
    let allItems = saleUnitItemsCount;
    let existingItemIndex = saleUnitItemsCount.findIndex(
      (f) => f.salesUnitItemId === salesUnitItem.id
    );
    if (existingItemIndex > -1) {
      allItems[existingItemIndex].count = count;
    } else {
      allItems.push({
        productId: product.id,
        productName: product.name,
        productUnit: product.unit,
        imageUrl:
          product.images !== undefined && product.images.length > 0
            ? product.images[0].name
            : null,
        salesUnitItemId: salesUnitItem.id,
        salesUnitItemUnitName: salesUnitItem.unitName,
        salesUnitItemPiece: salesUnitItem.piece,
        salesUnitItemUnitPrice: salesUnitItem.unitPrice,
        price: salesUnitItem.price,
        count: count,
      });
    }
    setSaleUnitItemsCount(allItems);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={props.dialogOpen}
      TransitionComponent={Transition}
      onClose={handleClose}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          fontFamily: "Satoshi-Medium",
        }}
      >
        {product != null ? product.name : ""}

        <IconButton
          aria-label="close"
          sx={{
            float: "right",
            color: (theme) => theme.palette.grey[500],
            padding: "0px",
          }}
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ pb: 2 }}>
          {product != null && (
            <ProductItemDetail
              product={product}
              setSaleUnitItemsCount={saleUnitItemsCountHandler}
              setFavorite={props.setFavorite}
              favorite={props.favorite}
            />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default ProductItemDialog;
