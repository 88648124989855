import { baseService } from "./baseService";
import { config } from "./config";

export const authenticationService = {
  login,
  register,
};

function login(username, password) {
  return baseService.post(config.endpoints.login, { username, password });
}

function register(
  username,
  password,
  passwordAgain,
  fullName,
  email,
  phone1,
  phone2,
  taxNumber,
  countryId,
  zipCode,
  address,
  city
) {
  return baseService.post(config.endpoints.register, {
    username,
    password,
    passwordAgain,
    fullName,
    email,
    phone1,
    phone2,
    taxNumber,
    countryId,
    zipCode,
    address,
    city
  });
}
