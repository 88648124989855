import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BasketContext from "../../store/basket-context";
import { Box, Typography, Button } from "@mui/material";
import useFormatHelper from "../../useFormatHelper";
import BasketProductItem from "../../components/BasketProductItem/BasketProductItem";
import "./SidebarBasket.css";

function SidebarBasket(props) {
  const { basketContext } = useContext(BasketContext);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { formatMoney } = useFormatHelper();

  const goBasket = () => {
    navigate("/payment");
  };

  useEffect(() => {
    basketContext.getItemsByService();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basketContext.counter]);

  useEffect(() => {
    if (basketContext.items.length > 0) {
      basketContext.items.reverse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basketContext.items.length]);

  return (
    <div className="sidebar-basket">
      <div className="widget-title">
        <div className="title">
          <i className="ri-shopping-cart-line" />
          {t("shopping_card")}
        </div>
        <span>{basketContext.items.length}</span>
      </div>
      <div className="widget-body">
        {basketContext !== null &&
          basketContext.items !== null &&
          basketContext.items.length > 0 &&
          basketContext.items.map((basketItem, index) => {
            return (
              <BasketProductItem
                limit={20}
                key={`basket_item_${basketItem.productId}_${basketItem.saleUnitId}_${index}`}
                basketItem={basketItem}
                readonly={props.readonly}
                first={index === 0}
                last={basketContext.items.length === index + 1}
              />
            );
          })}
        <>
          {basketContext.items.length === 0 ? (
            <div className="total">{t("my_basket_empty")}</div>
          ) : (
            <>
              <div className="total">
                {basketContext.taxAmounts != null && (
                  <>
                    <Box
                      sx={{
                        width: "100%",
                      }}
                    >
                      <Box className="total-item">
                        <Typography variant="axt">
                          {t("my_basket_total")}
                        </Typography>
                        <Typography variant="bxt">
                          {isNaN(basketContext.netAmount)
                            ? "-,"
                            : formatMoney(basketContext.netAmount)}
                          €
                        </Typography>
                      </Box>
                      <Box className="total-item">
                        <Typography variant="axt">{t("total_tax")}</Typography>
                        <Typography variant="bxt">
                          {isNaN(basketContext.netAmount)
                            ? "-,"
                            : formatMoney(
                                basketContext.totalAmount -
                                  basketContext.netAmount
                              )}
                          €
                        </Typography>
                      </Box>
                      <Box className="total-item">
                        <Typography variant="axt">
                          {t("total_price")}
                        </Typography>
                        <Typography variant="bxt">
                          {isNaN(basketContext.totalAmount)
                            ? "-,"
                            : formatMoney(basketContext.totalAmount)}
                          €
                        </Typography>
                      </Box>
                    </Box>
                  </>
                )}
              </div>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  textTransform: "none",
                  pt: "14px",
                  pb: "14px",
                  borderRadius: "30px",
                  mt: 2,
                }}
                onClick={() => {
                  goBasket();
                }}
              >
                {t("go_to_cart")}
              </Button>
            </>
          )}
        </>
      </div>
    </div>
  );
}

export default SidebarBasket;
