import React, { useCallback, useEffect, useRef, useState } from "react";
import "./ControlDetail.css";
import { orderService } from "../../../services/control/orderService";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { TextField } from "@mui/material";
import ProductItem from "../../../components/Control/ProductItem/ProductItem";
import useFormatHelper from "../../../useFormatHelper";
import { useContext } from "react";
import { OrderContext } from "../../../store/control/OrderProvider";
import ProductIApprovedtem from "../../../components/Control/ProductIApprovedtem/ProductIApprovedtem";
import { useTranslation } from "react-i18next";
import BarcodeItemDialog from "../../../components/Control/BarcodeItemDialog/BarcodeItemDialog";

export const ControlDetail = () => {
  const { orderId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { formatMoney } = useFormatHelper();

  const [order, setUnApprovedOrder] = useState([]);
  const [approvedOrder, setApprovedOrder] = useState([]);
  const [orderInfo, setOrderInfo] = useState([]);
  const [counter, setCounter] = useState(0);
  const [loading, setLoading] = useState(false);
  const [barcode, setBarcode] = useState('');
  const [totalPrice, setTotalPrice] = useState(0);
  const [barcodeDialogOpen, setBarcodeDialogOpen] = useState(false);

  const inputRef = useRef(null);

  const { status, setItems, items, approvedItems, unapprovedItems, getTotalPrice, setOrderStatu, getOrderStatu } = useContext(OrderContext)

  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    getOrderItem();
    getOrderInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [loading, counter]);

  const fetchProducts = useCallback(() => {
    setApprovedOrder(approvedItems);
    setUnApprovedOrder(unapprovedItems);
    setTotalPrice(getTotalPrice);
    setLoading(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items])

  useEffect(() => {
    fetchProducts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchProducts]);

  const generateUnApprovedPdf = (orderId, status) => {
    orderService
      .orderPdf(orderId, status)
      .then((response) => {
        var byteCharacters = atob(response.base64PdfData);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([byteArray], { type: "application/pdf" });

        var blobUrl = URL.createObjectURL(blob);

        window.open(blobUrl, "_blank");
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });

      setAutoFocus();
  }

  const getOrderItem = () => {
    orderService.getOrderItem(orderId).then((response) => {
      setItems(response);
    })
  }

  const getOrderInfo = () => {
    orderService.getOrderInfo(orderId).then((response) => {
      setOrderInfo(response);
      setOrderStatu(response.statusId);
    });
  }

  const createWayBill = (orderId) => {
    const confirmation = window.confirm(t('confirm'));

    if (confirmation) {
      orderService
        .createWayBill(orderId)
        .then((response) => {
          enqueueSnackbar('Create way bill success', {
            variant: "success",
          });

          getOrderInfo();
          navigate("/apps/control/waybills/?id=" + response.waybillId);
          //getWaybillPDf(response.waybillId);
        })
        .catch((error) => {
          enqueueSnackbar(error, {
            variant: "error",
          });
        });
    }

    setAutoFocus();
  }

  const createInvoice = (orderId) => {
    const confirmation = window.confirm(t('confirm'));

    if (confirmation) {
      orderService
        .createInvoice(orderId)
        .then((response) => {
          enqueueSnackbar('Create invoice success', {
            variant: "success",
          });

          getOrderInfo();
          navigate("/apps/control/invoices/?id=" + response.invoiceId);

          // getWaybillPDf(response.invoiceId);
        })
        .catch((error) => {
          enqueueSnackbar(error, {
            variant: "error",
          });
        });
    }

    setAutoFocus();
  }

  const changeOrderStatus = (orderId, status) => {
    orderService
      .changeOrderStatus(orderId, status)
      .then((response) => {
        setOrderStatu(status);

        enqueueSnackbar('Status changed', {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });

      setAutoFocus();
  }

  const approveProduct = (orderItem, count) => {
    if (!count || count <= 0) {
      enqueueSnackbar("Unvalid count", {
        variant: "error",
      });

      return;
    }

    orderService
      .approveProduct(orderItem.orderProductId, count)
      .then((response) => {
        enqueueSnackbar('Approve product', {
          variant: "success",
        });

        const existingItemIndex = approvedOrder.findIndex(item => item.orderProductId === orderItem.orderProductId);

        let firstSet = false;
        let countX = 0;

        if (existingItemIndex !== -1) {
          const updatedApprovedOrder = [...approvedOrder];

          const currentCount = parseInt(updatedApprovedOrder[existingItemIndex].numberOfApprovedItems);
          const countNumber = parseInt(count);
          updatedApprovedOrder[existingItemIndex].numberOfApprovedItems = currentCount + countNumber;
          countX = updatedApprovedOrder[existingItemIndex].numberOfApprovedItems;

          setApprovedOrder(updatedApprovedOrder.sort((a, b) => {
            if (orderItem.orderProductId == b.orderProductId) {
              return 1;
            }

            if (orderItem.orderProductId == a.orderProductId) {
              return -1;
            }

            return 0;
          }));
        } else {
          setApprovedOrder(prevApprovedOrder => {
            const newOrder = [...prevApprovedOrder, { ...orderItem, numberOfApprovedItems: count }];
            return newOrder.sort((a, b) => {
              if (orderItem.orderProductId == b.orderProductId) {
                return 1;
              }

              if (orderItem.orderProductId == a.orderProductId) {
                return -1;
              }

              return 0;
            });
          });
          firstSet = true;
        }

        setUnApprovedOrder(prevOrder => {
          const updatedOrder = prevOrder.map(item => {
            if (item.orderProductId === orderItem.orderProductId) {
              if (firstSet) {
                return { ...item, numberOfApprovedItems: (item.numberOfApprovedItems ? item.numberOfApprovedItems : 0) + count };

              } else {
                return { ...item, numberOfApprovedItems: countX };

              }
            }

            return item;
          }).filter(Boolean);
          return updatedOrder;
        });

        changeToInProgress();

      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });

      setAutoFocus();
  }

  const unApproveProduct = (orderItem, count) => {
    if (!count || count <= 0) {
      enqueueSnackbar("Unvalid count", {
        variant: "error",
      });

      return;
    }

    orderService
      .approveProduct(orderItem.orderProductId, -count)
      .then((response) => {
        enqueueSnackbar('Unapproved product', {
          variant: "success",
        });

        const existingItemIndex = approvedOrder.findIndex(item => item.orderProductId === orderItem.orderProductId);

        let firstSet = false;
        let countX = 0;

        if (existingItemIndex !== -1) {
          const updatedApprovedOrder = [...approvedOrder];
          countX = updatedApprovedOrder[existingItemIndex].numberOfApprovedItems -= count;
          setApprovedOrder(approvedOrder);
          firstSet = true;

          if (updatedApprovedOrder[existingItemIndex].numberOfApprovedItems === 0) {
            const newApprovedOrder = updatedApprovedOrder.filter(item => item.orderProductId !== orderItem.orderProductId);
            setApprovedOrder(newApprovedOrder);
          }
        }

        setUnApprovedOrder(prevOrder => {
          const updatedOrder = prevOrder.map(item => {
            if (item.orderProductId === orderItem.orderProductId) {
              if (firstSet) {
                return { ...item, numberOfApprovedItems: countX };
              } else {
                return { ...item, numberOfApprovedItems: item.numberOfApprovedItems - count };
              }
            }

            return item;
          }).filter(Boolean);
          return updatedOrder;
        });

      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });

      setAutoFocus();
  }

  const getInvoicePDf = (invoiceId) => {
    orderService
      .getInvoicePdf(invoiceId)
      .then((response) => {
        var byteCharacters = atob(response.data);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([byteArray], { type: "application/pdf" });

        var blobUrl = URL.createObjectURL(blob);

        window.open(blobUrl, "_blank");
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });

      setAutoFocus();
  }

  const getWaybillPDf = (wayybillId) => {
    orderService
      .getWayybilPdf(wayybillId)
      .then((response) => {
        var byteCharacters = atob(response.data);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([byteArray], { type: "application/pdf" });

        var blobUrl = URL.createObjectURL(blob);

        window.open(blobUrl, "_blank");
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
        });
      });

      setAutoFocus();
  }

  const readBarcode = (event) => {
    if (event.key === 'Enter') {
      let product = null;
      items.forEach(x => {
        if (product) return;

        let barcodes = x.barcodes.split(',');
        barcodes.forEach(y => {
          if (y === barcode) {
            product = x;

            return;
          }
        });
      });

      if (product !== null) {
        approveProduct(product, 1);
        const successSound = document.getElementById('successSound');
        successSound.play();
      }

      if (product === null) {
        setBarcodeDialogOpen(true);
        const errorSound = document.getElementById('errorSound');
        errorSound.play();
      }

      setBarcode('');
    }
  }

  const changeToInProgress = () => {
    if (getOrderStatu == 11) {
      changeOrderStatus(orderId, 12)
    }
  }

  const groupBySalesUnit = (items, isApproved) => {
    return items.reduce((acc, item) => {
      const { salesUnitName, numberOfItems, numberOfApprovedItems } = item;

      if (!acc[salesUnitName]) {
        acc[salesUnitName] = 0;
      }

      if (!isApproved) {
        acc[salesUnitName] += numberOfApprovedItems !== undefined ? numberOfItems - numberOfApprovedItems : (numberOfItems - (numberOfApprovedItems ?? 0));
      } else {
        acc[salesUnitName] += numberOfApprovedItems;
      }

      return acc;
    }, {});
  };

  const setAutoFocus = () => {
    setCounter(counter + 1);
  }

  const renderGroupedItems = (groupedItems) => {
    return Object.keys(groupedItems).map((key) => (
      <div key={key}>
        {key}: {groupedItems[key]}
      </div>
    ));
  };

  const approvedItemsGrouped = groupBySalesUnit(approvedOrder, true);
  const unapprovedItemsGrouped = groupBySalesUnit(order, false);

  return (
    loading === false ? (
      <div>Loading</div>
    ) : (
      <div className="header-container">
        <div className="control-detail">
          <div className="control-detail-left">
            <div className="control-flex">
              <div className="control-detail-invoice-detail">
                <div className="control-address">
                  <div className="control-detail-invoice-left">
                    <p>{orderInfo.company}</p>
                    <p className="secondline"> {orderInfo.address}
                      <br />
                      {orderInfo.zipCode},{orderInfo.city} {orderInfo.country}
                    </p>
                  </div>
                  <div className="control-detail-invoice-right">
                    <p>{t('order_no')}{orderInfo.orderNumber}</p>
                    <p>{t('order-date')}: {orderInfo.orderDateStr} </p>
                    <p>{t('seller')}: {orderInfo.sellerName} </p>
                  </div>
                </div>
              </div>
              <div className="control-detail-right">
                <div className="control-price-group">
                  <div className="control-old-price">Tutar: {formatMoney(totalPrice)}€</div>
                  <div className="control-detail-buttons">
                    {!orderInfo.waybillDate ? (
                      <button className="first" onClick={() => createWayBill(orderId)}>{t("create_wayybil")}</button>
                    ) : (
                      <button className="first" onClick={() => getWaybillPDf(orderInfo.waybillNumber)}>İrsaliye Indir</button>
                    )}
                    {!orderInfo.invoiceDate ? (
                      <button className="second" onClick={() => createInvoice(orderId)}>{t("create_invoice")}</button>
                    ) : (
                      <button className="second" onClick={() => getInvoicePDf(orderInfo.invoiceNumber)}>Fatura Indir</button>
                    )}

                  </div>
                </div>


                <div className="control-detail-right-flex">
                  <div className="control-detail-right-top">
                    <div className="control-status-buttons">
                      {status && status.map(item => (
                        <button className={getOrderStatu === item.id && `active-statu`} onClick={() => changeOrderStatus(orderId, item.id)}>{item.name}</button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {orderInfo.note && (
              <div className="control-detail-bottom">
                <div className="control-note">Note: {orderInfo.note}</div>
              </div>
            )}
          </div>
        </div>
        <div className="control-tables">
          <div className="left-table control-table">
            <div className="table-top">
              <div className="input">
                <TextField
                  placeholder={t('barcode')}
                  className="barcode-input"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  value={barcode}
                  onChange={(e) => setBarcode(e.target.value)}
                  onKeyDown={readBarcode}
                  inputRef={inputRef}
                />
              </div>
              <div className="control-table-head-right">
                <div className="grouped-items">{renderGroupedItems(unapprovedItemsGrouped)}</div>
                <div className="control-print">
                  <i className="ri-printer-line" onClick={() => generateUnApprovedPdf(orderId, false)} />
                </div>
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Produkt</th>
                  <th>Menge</th>
                  <th>Typ</th>
                  <th>E-Preis</th>
                  <th>G-Preis</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {approvedOrder !== null && order.length > 0 && order.map((orderItem) => <ProductItem orderItem={orderItem} approveProduct={approveProduct} setAutoFocus={setAutoFocus}/>)}
              </tbody>
            </table>
          </div>
          <div className="right-table control-table">
            <div className="table-top">
              <div className="control-table-head-right">
                <div className="control-print">
                  <i className="ri-printer-line" onClick={() => generateUnApprovedPdf(orderId, true)} />
                </div>
                <div className="grouped-items">{renderGroupedItems(approvedItemsGrouped)}</div>
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Produkt</th>
                  <th>Menge</th>
                  <th>Typ</th>
                  <th>E-Preis</th>
                  <th>G-Preis</th>
                </tr>
              </thead>
              <tbody>
                {order !== null && approvedOrder.length > 0 && approvedOrder.map((orderItem) => <ProductIApprovedtem orderItem={orderItem} unApproveProduct={unApproveProduct} setAutoFocus={setAutoFocus} />)}
              </tbody>
            </table>
          </div>
        </div>
        <>
          <BarcodeItemDialog dialogOpen={barcodeDialogOpen} setDialogOpen={setBarcodeDialogOpen} />
          <audio id="errorSound" src="../../../wrong.mpeg"/>
          <audio id="successSound" src="../../../success.mpeg"/>
        </>
      </div>
    )
  );
};
