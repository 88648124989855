// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.saleUnitPiece {
  width: 100px;
}

.saleUnitPiece input {
  text-align: center;
  padding: 5px;
}

.rowProd {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9fafb;
  border-radius: 16px;
  border: 1px solid #e9ebf1;
  padding: 12px 10px;
}

.rowProd .unit {
  color: #546076;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.16px;
}
.rowProd .unit span {
  color: #546076;
  font-family: Oswald;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.16px;
}
.rowProd .second {
  display: flex;
  align-items: center;
  gap: 10px;
}
.rowProd .second .MuiTypography-bigPrice {
  color: #151518;
  font-family: Oswald;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.22px;
}
`, "",{"version":3,"sources":["webpack://./src/components/SaleUnitItem/SaleUnitItem.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,yBAAyB;EACzB,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,cAAc;EACd,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;AACA;EACE,cAAc;EACd,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".saleUnitPiece {\r\n  width: 100px;\r\n}\r\n\r\n.saleUnitPiece input {\r\n  text-align: center;\r\n  padding: 5px;\r\n}\r\n\r\n.rowProd {\r\n  width: 100%;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n  background-color: #f9fafb;\r\n  border-radius: 16px;\r\n  border: 1px solid #e9ebf1;\r\n  padding: 12px 10px;\r\n}\r\n\r\n.rowProd .unit {\r\n  color: #546076;\r\n  font-size: 14px;\r\n  font-style: normal;\r\n  font-weight: 500;\r\n  line-height: normal;\r\n  letter-spacing: -0.16px;\r\n}\r\n.rowProd .unit span {\r\n  color: #546076;\r\n  font-family: Oswald;\r\n  font-size: 14px;\r\n  font-style: normal;\r\n  font-weight: 400;\r\n  line-height: normal;\r\n  letter-spacing: -0.16px;\r\n}\r\n.rowProd .second {\r\n  display: flex;\r\n  align-items: center;\r\n  gap: 10px;\r\n}\r\n.rowProd .second .MuiTypography-bigPrice {\r\n  color: #151518;\r\n  font-family: Oswald;\r\n  font-size: 22px;\r\n  font-style: normal;\r\n  font-weight: 600;\r\n  line-height: normal;\r\n  letter-spacing: -0.22px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
