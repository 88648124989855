import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import ControlDashboard from "../../pages/Control/Dashboard/ControlDashboard";
import Header from "../../components/Control/Header/Header";
import { ControlDetail } from "../../pages/Control/Detail/ControlDetail";
import { useContext, useEffect, useState } from "react";
import LoginContext from "../../store/login-context";
import OrderProvider from "../../store/control/OrderProvider";
import ControlInvoice from "../../pages/Control/Invoice/ControlInvoice";
import ControlWaybill from "../../pages/Control/Waybill/ControlWaybill";

const ControlMain = (props) => {

  const { loginContext } = useContext(LoginContext);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    if(loginContext.user && loginContext.user.roleName !== "STOCK_AGENT") {
      loginContext.setLoginState("user", null);
      navigate("/apps/login");
    }else if (!loginContext.user) {
      navigate("/apps/login");
    } else {
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <div>Loading...</div>; 
  }

  return (
    <OrderProvider>
      <div className="mainLayout">
        <div className="flex-main">
          <Header logo={props.logo} />
          <Routes>
            <Route
              path="/dashboard"
              element={
                <Box component="main">
                  <Box>
                    <div className="content control-dashboard-content">
                      <ControlDashboard />
                    </div>
                  </Box>
                </Box>
              }
            />
            <Route
              path="/control-item/:orderId"
              element={
                <Box component="main">
                  <Box>
                    <div className="content control-dashboard-content">
                      <ControlDetail />
                    </div>
                  </Box>
                </Box>
              }
            />
            <Route
              path="/invoices"
              element={
                <Box component="main">
                  <Box>
                    <div className="invoice-content">
                      <ControlInvoice />
                    </div>
                  </Box>
                </Box>
              }
            />
            <Route
              path="/waybills"
              element={
                <Box component="main">
                  <Box>
                    <div className="invoice-content">
                      <ControlWaybill />
                    </div>
                  </Box>
                </Box>
              }
            />
            <Route path="/" element={<Navigate to="/apps/control/dashboard" />} />
            <Route path="*" element={<Navigate to="/apps/control" />} />
          </Routes>
        </div>
      </div>
    </OrderProvider>
  );
};

export default ControlMain;
