import { CardMedia } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { tns } from "tiny-slider/src/tiny-slider";
import { useStateIfMounted } from "use-state-if-mounted";
import { advertisingService } from "../../services/advertisingService";
import { config } from "../../services/config";
import "./tiny-slider.css";

function Advertising() {
  const [advertisements, setAdvertisements] = useStateIfMounted([]);
  const [show, setShow] = useStateIfMounted(false);

  useEffect(() => {
    const advertisementsByStorage = sessionStorage.getItem("advertisements");
    if (advertisementsByStorage) {
      const advertisementsArray = JSON.parse(advertisementsByStorage);
      if (advertisementsArray.length > 0) {
        setAdvertisements(advertisementsArray);
      } else {
        advertisingService.getAdvertisements().then((response) => {
          sessionStorage.setItem("advertisements", JSON.stringify(response));
          setAdvertisements(response);
        });
      }
    } else {
      advertisingService.getAdvertisements().then((response) => {
        sessionStorage.setItem("advertisements", JSON.stringify(response));
        setAdvertisements(response);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (advertisements.length > 0) {
      setTimeout(() => {
        tns({
          container: ".my-slider",
          autoplay: true,
          controls: true,
          nav: false,
          navPosition: "bottom",
          autoplayButtonOutput: false,
          controlsText: [
            "<i class='ri-arrow-left-line'></i>",
            "<i class='ri-arrow-right-line'></i>",
          ],
        });
        setShow(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advertisements]);

  return (
    advertisements.length > 0 && (
      <Box sx={{ display: show ? "block" : "none", mb: 2 }}>
        <div className="my-slider">
          {advertisements.map((advertising) => (
            <CardMedia
              key={advertising.id}
              component="img"
              height="300"
              image={config.baseOrigin + advertising.image}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/no-photo.png";
              }}
            />
          ))}
        </div>
      </Box>
    )
  );
}

export default Advertising;
