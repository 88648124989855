import { Box, Dialog, DialogContent, DialogTitle, IconButton, TextField } from "@mui/material"
import "./UnApprovedDialog.css";
import { config } from "../../../services/config";
import { Add, Remove } from "@mui/icons-material";
import { useEffect, useState } from "react";

const UnApprovedDialog = (props) => {
  const orderItem = props.orderItem;
  const baseOrigin = config.baseOrigin;
  const defaultImg = "/no-photo.png";

  const max = orderItem.numberOfApprovedItems;
  const [count, setCount] = useState(max);

  useEffect(() => {
    setCount(max);
  }, [max]);

  const handleClose = () => {
    props.setAutoFocus(true);
    props.setDialogOpen(false);
  };

  const handleCount = (value) => {
    if (value && value > 0) {
      setCount(value);
    } else {
      setCount(null);
    }
  }

  const handleClick = (orderItem, count) => {
    props.unApproveProduct(orderItem, count);
    setCount(max - count);
    handleClose();
  }

  const add = () => {
    if (count < max) {
      setCount(count + 1);
    }
  }

  const minus = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={props.dialogOpen}
      onClose={handleClose}
    >
      <DialogTitle className="control-dialog-title yellow-bg">
        Geri Alma
      </DialogTitle>
      <DialogContent dividers>
        <Box>
          <div className="control-dialog-top">
            <div className="control-dialog-product-info">
              <div className="control-image">
                <img
                  src={orderItem.productImage ? baseOrigin + orderItem.productImage : defaultImg}
                  alt={orderItem.productName}
                  style={{ width: '350px', height: '350px', objectFit: 'contain' }}
                />
              </div>
              <div className="dialog-control-right">
                <span>{orderItem.productName}({orderItem.salesUnitName})</span>
                <div className="control-dialog-product-top">
                  <TextField value={count} onChange={(e) => handleCount(e.target.value)} sx={{ width: "100px" }} />
                  <div className="control-count-buttons">
                    <button onClick={() => minus()}><Remove /></button>
                    <button onClick={() => add()}><Add /></button>
                  </div>
                </div>
                <p> adet onayı kaldırılacak emin misiniz?</p>
                <div className="control-dialog-bottom">
                  <button onClick={() => handleClose()}>Hayır</button>
                  <button className="close" onClick={() => handleClick(orderItem, count)}>Evet</button>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default UnApprovedDialog;