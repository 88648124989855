import { useReducer } from "react";
import ProductContext from "./product-context";

const productReducer = (state, action) => {
  if (action.type === "SET") {
    let stateObj = state;
    if (
      action.key === "search" ||
      action.key === "categorySubCategoryInfo" ||
      action.key === "brandId" ||
      action.key === "favorite" ||
      action.key === "clear"
    ) {
      window.scroll(0, 0);
      stateObj = { page: 0 };
    }
    stateObj[action.key] = action.value;
    return { ...stateObj };
  }
};

const ProductProvider = (props) => {
  const [productState, dispatchAction] = useReducer(productReducer, {});

  const setStateHandler = (key, value) => {
    dispatchAction({
      type: "SET",
      key: key,
      value: value,
    });
  };

  const getStateHandler = (key) => {
    return productState[key];
  };

  const clearStateHandler = () => {
    setStateHandler("clear");
  };

  const productContext = {
    setProductState: setStateHandler,
    getProductState: getStateHandler,
    clearState: clearStateHandler,
  };

  return (
    <ProductContext.Provider value={{ productContext }}>
      {props.children}
    </ProductContext.Provider>
  );
};

export default ProductProvider;
