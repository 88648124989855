// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-block {
  width: 70px;
  height: 70px;
  border: 1px solid #f3f0fe;
  border-radius: 12px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.bold {
  color: #151518 !important;
  font-weight: 400 !important;
  font-size: 15px !important;
}
.dx-dx {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0px 8px;
  display: inline-flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background-color: #ff1d16;
  color: #fff;
  font-family: "Satoshi-Medium";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.12px;
}
.sidebar-basket .shopping-card {
  position: relative;
}
`, "",{"version":3,"sources":["webpack://./src/components/BasketProductItem/BasketProductItem.css"],"names":[],"mappings":"AACA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,mBAAmB;EACnB,cAAc;EACd,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,qBAAqB;AACvB;AACA;EACE,yBAAyB;EACzB,2BAA2B;EAC3B,0BAA0B;AAC5B;AACA;EACE,kBAAkB;EAClB,QAAQ;EACR,MAAM;EACN,sBAAsB;EACtB,oBAAoB;EACpB,iBAAiB;EACjB,uBAAuB;EACvB,mBAAmB;EACnB,QAAQ;EACR,yBAAyB;EACzB,WAAW;EACX,6BAA6B;EAC7B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,kBAAkB;AACpB","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap\");\r\n.image-block {\r\n  width: 70px;\r\n  height: 70px;\r\n  border: 1px solid #f3f0fe;\r\n  border-radius: 12px;\r\n  flex-shrink: 0;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  align-content: center;\r\n}\r\n.bold {\r\n  color: #151518 !important;\r\n  font-weight: 400 !important;\r\n  font-size: 15px !important;\r\n}\r\n.dx-dx {\r\n  position: absolute;\r\n  right: 0;\r\n  top: 0;\r\n  border-radius: 0px 8px;\r\n  display: inline-flex;\r\n  padding: 4px 10px;\r\n  justify-content: center;\r\n  align-items: center;\r\n  gap: 5px;\r\n  background-color: #ff1d16;\r\n  color: #fff;\r\n  font-family: \"Satoshi-Medium\";\r\n  font-size: 12px;\r\n  font-style: normal;\r\n  font-weight: 700;\r\n  line-height: normal;\r\n  letter-spacing: -0.12px;\r\n}\r\n.sidebar-basket .shopping-card {\r\n  position: relative;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
