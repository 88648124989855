import { Box } from "@mui/material";
import React from "react";
import useFormatHelper from "../../useFormatHelper";
import "./PaymentItem.css";

function PaymentItem(props) {
  const orderItem = props.orderItem;
  const { formatDateTime, formatMoney } = useFormatHelper();

  return (
    orderItem != null && (
      <Box>
        <Box
          className="previous-item"
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box className="left-box">
            <div className="left-box-in">
              <div className="order-id">
                {orderItem.paymentTypeName} :{" "}
                {formatMoney(orderItem.paidAmount)}€
              </div>
              <span className="date">
                {" "}
                {formatDateTime(orderItem.paymentDate)}
              </span>
            </div>
          </Box>

          <Box className="right-box">
            <div className="invoice-big-price">
              {formatMoney(orderItem.totalAmount)}€
            </div>
          </Box>
        </Box>
      </Box>
    )
  );
}

export default PaymentItem;
