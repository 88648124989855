import { Box, Chip } from "@mui/material";
import React, { useContext, useEffect, useRef } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import { brandService } from "../../services/brandService";
import ProductContext from "../../store/product-context";
import "./BrandBar.css";

function BrandBar() {
  const previousController = useRef();
  const { productContext } = useContext(ProductContext);
  const [brands, setBrands] = useStateIfMounted([]);
  const [brandId, setBrandId] = useStateIfMounted(null);
  const [hasChanged, setHasChanged] = useStateIfMounted(true);

  useEffect(() => {
    if (!hasChanged) {
      setHasChanged(true);
      return;
    }

    const body = {};

    const categorySubCategoryInfo = productContext.getProductState(
      "categorySubCategoryInfo"
    );

    const search = productContext.getProductState("search");
    const page = productContext.getProductState("page");
    const favorite = productContext.getProductState("favorite");

    if (
      categorySubCategoryInfo !== undefined &&
      categorySubCategoryInfo.categoryId
    ) {
      body["categoryId"] = categorySubCategoryInfo.categoryId;
    }

    if (
      categorySubCategoryInfo !== undefined &&
      categorySubCategoryInfo.subCategoryId
    ) {
      body["subCategoryIdList"] = [categorySubCategoryInfo.subCategoryId];
    }
    if (search) {
      body["searchKeyword"] = search;
    }
    if (favorite) {
      body["favorite"] = favorite;
    }
    if (page != null) {
      const controller = new AbortController();
      const signal = controller.signal;
      previousController.current = controller;
      brandService.getBrands(body, { signal }).then((response) => {
        const brandsResponse = response.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
        setBrands(brandsResponse);
        const brandId = productContext.getProductState("brandId");
        setBrandId(brandId);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productContext]);

  const brandChangeHandler = (_brandId) => {
    if (_brandId === brandId) {
      _brandId = null;
    } else {
      setHasChanged(false);
    }
    setBrandId(_brandId);
    productContext.setProductState("brandId", _brandId);
  };

  return (
    <div className="brand-container">
      <Box
        sx={{
          mb: 1,
          pb: 1,
          display: "flex",
          width: "100%",
          overflow: "auto",
        }}
        className="brand-bar"
      >
        {brands !== undefined &&
          brands.map((brand) => {
            return (
              <Chip
                key={"brand_chip_" + brand.id}
                label={brand.name}
                sx={{ mr: 1 }}
                onClick={() => {
                  brandChangeHandler(brand.id);
                }}
              />
            );
          })}
      </Box>
    </div>
  );
}

export default BrandBar;
