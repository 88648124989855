import { baseService } from "./baseService.js";
import { config } from "./config";

export const orderService = {
  setBasketItem,
  setBasketItems,
  getBasketItems,
  getBasketConfirmation,
  approveBasket,
  getOrders,
  getOrderItems,
  getInvoices,
  getPdf,
  getPayments,
  getOpenAmounts,
};

function setBasketItem(body) {
  return baseService.post(config.endpoints.setBasketItem, body);
}
function setBasketItems(body) {
  return baseService.post(config.endpoints.setBasketItems, body);
}
function getBasketItems() {
  return baseService.post(config.endpoints.getBasketItems);
}

function getBasketConfirmation() {
  return baseService.post(config.endpoints.basketConfirmation);
}

function approveBasket(note) {
  return baseService.post(config.endpoints.approveBasket, { note: note });
}

function getOrders(page, size) {
  const sizeObject = { page: page, size: size };
  return baseService.post(
    config.endpoints.getOrders,
    size > 0 ? sizeObject : null
  );
}

function getOrderItems(orderId) {
  return baseService.post(
    config.endpoints.getOrderItems.replace(":orderId", orderId)
  );
}

function getInvoices(page, size) {
  const sizeObject = { page: page, size: size };
  return baseService.post(
    config.endpoints.getInvoices,
    size > 0 ? sizeObject : null
  );
}

function getPdf(invoiceId) {
  return baseService.post(
    config.endpoints.getPdf.replace("{invoiceId}", invoiceId)
  );
}

function getPayments(page, size) {
  const sizeObject = { page: page, size: size };
  return baseService.post(
    config.endpoints.getPayments,
    size > 0 ? sizeObject : null
  );
}

function getOpenAmounts(showOrderAmount, showWaybillAmount, showInvoiceAmount) {
  const sizeObject = {
    showOrderAmount: showOrderAmount,
    showWaybillAmount: showWaybillAmount,
    showInvoiceAmount: showInvoiceAmount,
  };
  return baseService.post(config.endpoints.getOpenAmounts, sizeObject);
}
