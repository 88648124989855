import { CardMedia } from "@mui/material";
import React from "react";

function Logo(props) {
  return (
    <div>
      <CardMedia
        component="img"
        height="79"
        image={props.imageUrl}
        sx={{ objectFit: "contain" }}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = "/no-photo.png";
        }}
      />
    </div>
  );
}

export default Logo;
