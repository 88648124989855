import React from "react";
import "./PreLoader.css";

function PreLoader() {
  return (
    <div className="clock-loader-block">
      <div className="clock-loader" />
    </div>
  );
}

export default PreLoader;
