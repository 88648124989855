import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

const FooterItem = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);

  return (
    <Accordion>
      <AccordionSummary
        onClick={() => {
          setOpen(!open);
        }}
        expandIcon={
          open ? (
            <i className="ri-add-fill" />
          ) : (
            <i className="ri-subtract-line" />
          )
        }
      >
        <div className="widget-title"> {props.title}</div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="widget-body">
          <ul>
            <li>
              <a href="/page/about">{t("about_us")}</a>
            </li>
            <li>
              <a href="/page/contact">{t("contact")}</a>
            </li>
            <li>
              <a href="/page/impressum">{t("legal_notice")}</a>
            </li>
            <li>
              <a href="/page/agb">{t("conditions")}</a>
            </li>
          </ul>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default FooterItem;
