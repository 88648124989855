import { Add, Remove } from "@mui/icons-material";
import { Box, CircularProgress, Input, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import BasketContext from "../../store/basket-context";
import "./BasketCounter.css";

function BasketCounter(props) {
  const { basketContext } = useContext(BasketContext);
  const [count, setCount] = useStateIfMounted(0);
  const productId = props.productId;
  const productName = props.productName;
  const productUnit = props.productUnit;
  const imageUrl = props.imageUrl;
  const salesUnitItemId = props.salesUnitItemId;
  const salesUnitItemUnitName = props.salesUnitItemUnitName;
  const salesUnitItemPiece = props.salesUnitItemPiece;
  const salesUnitItemUnitPrice = props.salesUnitItemUnitPrice;
  const price = props.price;

  useEffect(() => {
    setCount(props.count);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCount(basketContext.getItem(productId, salesUnitItemId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basketContext, productId, salesUnitItemId]);

  const changeCount = (newCount) => {
    newCount = parseInt(newCount);
    if (newCount === "") {
      setCount();
    } else {
      basketContext.setItem(
        props.tax,
        productId,
        productName,
        productUnit,
        imageUrl,
        salesUnitItemId,
        salesUnitItemUnitName,
        salesUnitItemPiece,
        salesUnitItemUnitPrice,
        price,
        newCount
      );
    }
  };

  return (
    <Box
      className={
        (props.home === true ? "buttons-block home " : "buttons-block ") +
        (props.fullWidth === true ? "full-width " : " ") +
        (props.previousOrder === true ? "previous-order-btn" : "")
      }
    >
      {!props.readonly && (
        <Box className="button minus-button">
          <button
            disabled={
              !(count > 0) ||
              basketContext.processItems[
                `product_${props.productId}_saleUnit_${props.salesUnitItemId}`
              ]
            }
            onClick={() => {
              setCount(count - 1);
              basketContext.setItem(
                props.tax,
                productId,
                productName,
                productUnit,
                imageUrl,
                salesUnitItemId,
                salesUnitItemUnitName,
                salesUnitItemPiece,
                salesUnitItemUnitPrice,
                price,
                count - 1
              );
            }}
          >
            {count > 1 && <Remove />}
            {count === 1 && <i className="ri-delete-bin-5-line" />}
          </button>
        </Box>
      )}
      <Box
        className={
          (props.home === true ? "product-count home " : "product-count ") +
          (props.fullWidth === true ? "width80" : "")
        }
      >
        {basketContext.processItems[
          `product_${props.productId}_saleUnit_${props.salesUnitItemId}`
        ] ? (
          <CircularProgress size={20} sx={{ color: "#fff" }} />
        ) : (
          <Typography variant="body1" className="count-input">
            {props.previousOrder === true ? (
              "x" + props.count
            ) : (
              <Input
                value={count}
                type="number"
                onChange={(e) => changeCount(e.target.value)}
                min={1}
              />
            )}
          </Typography>
        )}
      </Box>
      {!props.readonly && (
        <Box className="button add-button">
          <button
            disabled={
              basketContext.processItems[
                `product_${props.productId}_saleUnit_${props.salesUnitItemId}`
              ]
            }
            onClick={() => {
              setCount(count + 1);
              basketContext.setItem(
                props.tax,
                productId,
                productName,
                productUnit,
                imageUrl,
                salesUnitItemId,
                salesUnitItemUnitName,
                salesUnitItemPiece,
                salesUnitItemUnitPrice,
                price,
                count + 1
              );
            }}
          >
            <Add />
          </button>
        </Box>
      )}
    </Box>
  );
}

export default BasketCounter;
