import React from "react";

const BasketContext = React.createContext({
  items: [],
  processItems: {},
  setItem: (
    tax,
    productId,
    productName,
    productUnit,
    imageUrl,
    salesUnitItemId,
    salesUnitItemUnitName,
    salesUnitItemPiece,
    salesUnitItemUnitPrice,
    price,
    count,
    defaultPrice,
    defaultUnitPrice
  ) => {},
  setItems: (items) => {},
  clearItems: () => {},
  getItem: (productId, salesUnitItemId) => {},
  getTotalPrice: () => {},
  getDiscountPrice: () => {},
  getDeliveryCost: () => {},
  getBadgeCount: () => {},
  sendItems: () => {},
  getItemsByService: (force) => {},
  getOrderConfirmationData: () => {},
  clearPrices: () => {},
  counter: 0,
});

export default BasketContext;
