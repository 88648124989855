// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clock-loader-block {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.clock-loader {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  border: 3px solid #007fff;
  border-radius: 50%;
}
.clock-loader::before,
.clock-loader::after {
  position: absolute;
  content: "";
  top: calc(calc(4rem / 2) * 0.25);
  width: 0.2rem;
  background: #007fff;
  border-radius: 10px;
  transform-origin: center calc(100% - calc(0.2rem / 2));
  animation: spin infinite linear;
}

.clock-loader::before {
  height: calc(4rem * 0.4);
  animation-duration: 2s;
}

.clock-loader::after {
  top: calc(calc(4rem / 2) * 0.25 + calc(4rem * 0.2));
  height: calc(4rem * 0.2);
  animation-duration: 15s;
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/PreLoader/PreLoader.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;AACpB;AACA;;EAEE,kBAAkB;EAClB,WAAW;EACX,gCAAgC;EAChC,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,sDAAsD;EACtD,+BAA+B;AACjC;;AAEA;EACE,wBAAwB;EACxB,sBAAsB;AACxB;;AAEA;EACE,mDAAmD;EACnD,wBAAwB;EACxB,uBAAuB;AACzB;;AAEA;EACE;IACE,wBAAwB;EAC1B;AACF","sourcesContent":[".clock-loader-block {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n}\n\n.clock-loader {\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 4rem;\n  height: 4rem;\n  border: 3px solid #007fff;\n  border-radius: 50%;\n}\n.clock-loader::before,\n.clock-loader::after {\n  position: absolute;\n  content: \"\";\n  top: calc(calc(4rem / 2) * 0.25);\n  width: 0.2rem;\n  background: #007fff;\n  border-radius: 10px;\n  transform-origin: center calc(100% - calc(0.2rem / 2));\n  animation: spin infinite linear;\n}\n\n.clock-loader::before {\n  height: calc(4rem * 0.4);\n  animation-duration: 2s;\n}\n\n.clock-loader::after {\n  top: calc(calc(4rem / 2) * 0.25 + calc(4rem * 0.2));\n  height: calc(4rem * 0.2);\n  animation-duration: 15s;\n}\n\n@keyframes spin {\n  to {\n    transform: rotate(1turn);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
