import { createContext, useEffect, useReducer } from "react";
import { orderService } from "../../services/control/orderService";

export const OrderContext = createContext();

const orderReducer = (state, action) => {
  switch (action.type) {
    case "SET_LIST":
      state.items = [];
      state.approvedItems = [];
      state.unapprovedItems = [];
      state.totalPrice = 0;

      action.item.map(item => {
        if(item.numberOfApprovedItems && item.numberOfApprovedItems > 0) {
          state.approvedItems.push(item);
        }
        
        if (item.numberOfApprovedItems || !item.numberOfApprovedItems) {
          state.unapprovedItems.push(item);
        }

        
        state.totalPrice += item.totalNetPrice;
      })

      state.counter = 1;

      return { ...state, items: action.item };
    case "SET_STATU":
      return { ...state, statu: action.item };
    case "SET_STATUS":
      return { ...state, status: action.item };
    case "CLEAR":
      state.counter = 0;

      return { ...state};
    default:
      return state;
  }
};

const OrderProvider = (props) => {
  let initialState = {
    items: [], 
    approvedItems: [], 
    unapprovedItems: [], 
    counter:0, 
    totalPrice: 0, 
    status: []
  };

  const [orderState, dispatchAction] = useReducer(orderReducer, initialState);

  const getItemsByService = async (orderId) => {
    try {
      const response = await orderService.getOrderItem(orderId);

      dispatchAction({
        type: "SET_LIST",
        item: response
      });

    } catch (error) {
      console.error('There was a problem fetching the data:', error);
    }
  };

  const setItems = (response) => {
    dispatchAction({
      type: "SET_LIST",
      item: response
    });
  };

  const setOrderStatu = (statu) => {
    dispatchAction({
      type: "SET_STATU",
      item: statu
    });
  }

  const getOrderStatus = async () => {
    await orderService.getOrderStatus().then((response) => {
      dispatchAction({
        type: "SET_STATUS",
        item: response
      });
    });
  }

  useEffect(() => {
    if(orderState.status.length === 0 ){
      getOrderStatus();
    }
  }, []);


  return (
    <OrderContext.Provider 
      value={{ 
        items: orderState.items,
        approvedItems: orderState.approvedItems,
        unapprovedItems: orderState.unapprovedItems,
        getTotalPrice: orderState.totalPrice,
        getOrderStatu : orderState.statu,
        status: orderState.status,
        getItemsByService,
        setItems,
        setOrderStatu
      }}
     >
      {props.children}
    </OrderContext.Provider>
  );
};

export default OrderProvider;