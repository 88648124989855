const DOMAIN =
  process.env.REACT_APP_DOMAIN || "http://vm43721.cs.easyname.systems:81";

export const config = {
  baseOrigin: DOMAIN,
  apiUrl: DOMAIN,
  otherApiUrl: DOMAIN + "/public/api",
  endpoints: {
    login: "/public/web-api/login",
    register: "/public/web-api/signup",
    getCategories: "/public/web-api/category/all",
    getCountries: "/public/web-api/country/list",
    getAdvertisements: "/public/web-api/advertising/all",
    getBrands: "/public/web-api/brand/all",
    getProductsBySorted: "/public/web-api/product/sorted",
    getProductById: "/public/web-api/product/{productId}",
    favorite:
      "/web-api/product/favorite?productId={productId}&favorite={favorite}",
    getCompanyInfo: "/public/web-api/company/get",
    getStoreURLs: "/public/web-api/company/storeURLs",
    setBasketItem: "/web-api/order/set-basket-item",
    setBasketItems: "/web-api/order/set-basket-items",
    getBasketItems: "/web-api/order/get-basket-items",
    basketConfirmation: "/web-api/order/basket-confirmation",
    approveBasket: "/web-api/order/approve-basket",
    getOrders: "/web-api/order/page/",
    getOrderItems: "/web-api/order/:orderId",
    customerInfo: "/web-api/customerInfo",
    aboutUs: "/configuration/aboutUs",
    contact: "/configuration/contact",
    impressum: "/configuration/impressum",
    agb: "/configuration/agb",
    getInvoices: "/web-api/invoice/page/",
    getPdf: "/web-api/invoice/pdf?invoiceId={invoiceId}",
    getPayments: "/web-api/payment/page/",
    getOpenAmounts: "/web-api/payment/openAmounts/",
    stockAgentLogin: "/public/web-api/stockAgentLogin",
    stockAgentOrders: "/api/stockAgent/order/page",
    stockAgentOrder: "/api/stockAgent/order/products",
    stockAgentCreateInvoice: "/api/stockAgent/order/createInvoice",
    stockAgentCreateWayBill: "/api/stockAgent/order/createWaybill",
    stockAgentApproveProduct: "/api/stockAgent/order/approveProduct",
    stockAgentChangeOrderStatus: "/api/stockAgent/order/changeOrderStatus",
    stockAgentOrderHeaderInfo: "/api/stockAgent/order/info",
    stockAgentOrderStatus: "/api/stockAgent/order/orderStatuses",
    stockAgentInvoicePdf: "/api/stockAgent/order/invoicePdf",
    stockAgentWayybillPdf: "/api/stockAgent/order/waybillPdf",
    stockAgentWayybills: "/api/stockAgent/waybill/page",
    stockAgentInvoices: "/api/stockAgent/invoice/page",
    orderPrint: "/api/stockAgent/order/orderPdf",
    notApprovedProductsPdf: "/api/stockAgent/order/notApprovedProductsPdf",
  },
};
