import { baseService } from "./baseService.js";
import { config } from "./config";

export const brandService = {
  getBrands,
};

function getBrands(body, signal) {
  return baseService.post(config.endpoints.getBrands, body, signal);
}
