import { Box, Paper } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import HeaderWrapper from "../../components/HeaderWrapper/HeaderWrapper";
import Footer from "../../components/Footer/Footer";
import Sidebar from "../../components/Sidebar/Sidebar";
import Login from "../../pages/auth/Login";
import Basket from "../../pages/Basket/Basket";
import PreviousOrder from "../../pages/PreviousOrder/PreviousOrder";
import PreviousOrderDetail from "../../pages/PreviousOrderDetail/PreviousOrderDetail";
import Product from "../../pages/Product/Product";
import "./Main.css";
import "remixicon/fonts/remixicon.css";
import SidebarBasket from "../../components/SidebarBasket/SidebarBasket";
import MyProfile from "../../pages/MyProfile/MyProfile";
import Favourites from "../../pages/Favourites/Favourites";
import Page from "../../pages/Page/Page";
import Invoices from "../../pages/Invoice/Invoice";
import Payments from "../../pages/Payment/Payment";
import LoginContext from "../../store/login-context";

function Main(props) {
  const [windowWidth, setWindowWith] = useState(window.innerWidth);
  const { loginContext } = useContext(LoginContext);

  useEffect(() => {

    if(loginContext.user && loginContext.user.roleName === "STOCK_AGENT") {
      loginContext.setLoginState("user", null);
    }

    setWindowWith(window.innerWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth]);

  let drawerWidth = 310;
  let basketWidth = 380;

  if (windowWidth < 1500) {
    drawerWidth = 260;
    basketWidth = 300;
  }

  return (
    <div className="mainLayout">
      <div className="flex-main">
        <HeaderWrapper logo={props.logo} storeURLs={props.storeURLs} />
        <Routes>
          <Route
            path="/"
            element={
              <div className="full-container">
                <Box component="main" className="main-container">
                  <Paper
                    elevation={0}
                    sx={{
                      float: "right",
                      width: `${basketWidth}px`,
                      maxWidth: `${basketWidth}px`,
                      position: "sticky",
                      top: "calc(75px + 0px)",
                      overflow: "auto",
                      height: "calc(100vh - 75px - 32px)",
                      display: { xs: "none", md: "flex" },
                      background: "#Fff",
                    }}
                    className="mobile-paper"
                  >
                    <Box sx={{ width: "100%" }}>
                      <SidebarBasket width={basketWidth} />
                    </Box>
                  </Paper>
                  <Paper
                    elevation={0}
                    sx={{
                      float: "left",
                      width: `${drawerWidth}px`,
                      maxWidth: `${drawerWidth}px`,
                      position: "sticky",
                      top: "calc(75px + 0px)",
                      overflow: "auto",
                      height: "calc(100vh - 75px - 32px)",
                      display: { xs: "none", md: "flex" },
                      background: "#F9FAFB",
                    }}
                    className="mobile-paper"
                  >
                    <Box sx={{ width: "100%" }}>
                      <Sidebar width={drawerWidth} />
                    </Box>
                  </Paper>
                  <Box
                    sx={{
                      flexGrow: 1,
                      maxWidth: {
                        xs: "100%",
                        md: `calc(100% - ${drawerWidth}px - ${basketWidth}px)`,
                      },
                      ml: { xs: 0, md: `${drawerWidth}px` },
                      borderRight: "1px solid #E9EBF1",
                      borderLeft: "1px solid #E9EBF1",
                    }}
                    className="main-content"
                  >
                    <div className="content">
                      <Product />
                    </div>
                  </Box>
                </Box>
              </div>
            }
          />
          <Route
            path="/basket"
            element={
              <Box component="main">
                <Box>
                  <Basket readonly={false} showDetail={false} />
                </Box>
              </Box>
            }
          />
          <Route
            path="/payment"
            element={
              <Box component="main">
                <Box>
                  <div className="content">
                    <Basket readonly={false} showDetail={true} />
                  </div>
                </Box>
              </Box>
            }
          />
          <Route
            path="/previous-orders"
            element={
              <Box component="main">
                <Box>
                  <div className="content">
                    <PreviousOrder />
                  </div>
                </Box>
              </Box>
            }
          />
          <Route
            path="/profile"
            element={
              <Box component="main">
                <Box>
                  <div className="content">
                    <MyProfile />
                  </div>
                </Box>
              </Box>
            }
          />
          <Route
            path="/previous-orders/:orderId"
            element={
              <Box component="main">
                <Box>
                  <div className="content">
                    <PreviousOrderDetail />
                  </div>
                </Box>
              </Box>
            }
          />
          <Route
            path="/favourites"
            element={
              <Box component="main">
                <Box>
                  <div className="content">
                    <Favourites />
                  </div>
                </Box>
              </Box>
            }
          />
          <Route
            path="/page/:pageId"
            element={
              <Box component="main">
                <Box>
                  <div className="content">
                    <Page />
                  </div>
                </Box>
              </Box>
            }
          />
          <Route
            path="/invoices"
            element={
              <Box component="main">
                <Box>
                  <div className="content">
                    <Invoices />
                  </div>
                </Box>
              </Box>
            }
          />
          <Route
            path="/payments"
            element={
              <Box component="main">
                <Box>
                  <div className="content">
                    <Payments />
                  </div>
                </Box>
              </Box>
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
      <Footer title={props.title} storeURLs={props.storeURLs} />
      <Login />
    </div>
  );
}

export default Main;
