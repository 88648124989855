function useFormatHelper() {
  function getLanguage() {
    const language = localStorage.getItem("i18nextLng");
    if (language) return language;
    return "de";
  }

  const formatMoney = (
    amount,
    decimalCount = 2,
    decimal = ",",
    thousands = ","
  ) => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString();
      let j = i.length > 3 ? i.length % 3 : 0;
      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
        (decimalCount
          ? decimal +
            Math.abs(amount - i)
              .toFixed(decimalCount)
              .slice(2)
          : "")
      );
    } catch (e) {
      console.log(e);
    }
  };

  const formatDate = (dateString) => {
    return new Date(Date.parse(dateString)).toLocaleTimeString(getLanguage(), {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const formatDateByShortDate = (dateString) => {
    return new Date(Date.parse(dateString)).toLocaleDateString(getLanguage(), {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
  };

  const formatDateTime = (dateString) => {
    return new Date(Date.parse(dateString)).toLocaleDateString(getLanguage(), {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hourCycle: "h23",
    });
  };

  const textTrim = (text) => {
    return text.replace(/\s/g, "");
  };

  return {
    formatMoney,
    formatDateTime,
    formatDate,
    formatDateByShortDate,
    textTrim,
  };
}

export default useFormatHelper;
