import { CardMedia } from "@mui/material";
import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ProductContext from "../../store/product-context";

function Logo(props) {
  const { productContext } = useContext(ProductContext);
  const location = useLocation();
  const navigate = useNavigate();
  const imageUrl = props.imageUrl;
  return (
    <div
      style={props.style}
      onClick={() => {
        if (location.pathname === "/") {
          productContext.clearState();
        } else {
          navigate("/");
        }
      }}
    >
      <CardMedia
        component="img"
        height="79"
        image={imageUrl}
        sx={{ objectFit: "contain" }}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = "/no-photo.png";
        }}
      />
    </div>
  );
}

export default Logo;
