import { useReducer } from "react";
import LoginContext from "./login-context";

const loginReducer = (state, action) => {
  if (action.type === "SET") {
    let stateObj = state;
    stateObj[action.key] = action.value;
    if (action.key === "user") {
      localStorage.setItem("user", JSON.stringify(action.value));
    }
    return { ...stateObj };
  }
};

const LoginProvider = (props) => {
  let defaultLoginState = {};
  const userInfoByStorage = localStorage.getItem("user");
  if (userInfoByStorage !== undefined) {
    defaultLoginState["user"] = JSON.parse(userInfoByStorage);
  }

  const [loginState, dispatchAction] = useReducer(
    loginReducer,
    defaultLoginState
  );

  const setStateHandler = (key, value) => {
    dispatchAction({
      type: "SET",
      key: key,
      value: value,
    });
  };

  const getStateHandler = (key) => {
    return loginState[key];
  };

  const loginContext = {
    user: loginState.user,
    setLoginState: setStateHandler,
    getLoginState: getStateHandler,
  };

  return (
    <LoginContext.Provider value={{ loginContext }}>
      {props.children}
    </LoginContext.Provider>
  );
};

export default LoginProvider;
