import { Navigate } from "react-router-dom";
import { baseService } from "./baseService.js";
import { config } from "./config";

export const pageService = {
  getPage,
};

function getPage(pageId) {
  switch (pageId) {
    case "about":
      return baseService.post(config.endpoints.aboutUs);
    case "contact":
      return baseService.post(config.endpoints.contact);
    case "impressum":
      return baseService.post(config.endpoints.impressum);
    case "agb":
      return baseService.post(config.endpoints.agb);
    default:
      Navigate("/");
      break;
  }
}
