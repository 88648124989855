import { baseService } from "./baseService.js";
import { config } from "./config";

export const categoryService = {
  getCategories,
};

function getCategories() {
  return baseService.post(config.endpoints.getCategories);
}
