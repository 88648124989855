// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.languageSelect .MuiSelect-icon {
  display: block;
}

.languageSelect.MuiInputBase-root:before,
.languageSelect.MuiInputBase-root:after,
.languageSelect .MuiSelect-select:focus {
  content: "" !important;
  border: 0 !important;
  background-color: transparent !important;
}

.languageSelect .MuiSelect-select {
  padding-left: 20px !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.14px;
  display: flex;
  align-items: center;
  gap: 5px;
  width: 40px;
  border: 0;
  text-align: center !important;
  padding-right: 10px !important;
}
.languageSelect .flag {
  padding-top: 2px;
}
.languageItem {
  display: block !important;
  border-bottom: 1px solid #e9ebf1 !important;
}
.languageItem .flag {
  display: none;
}
.languageItem:last-child {
  border-bottom: initial !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/LanguageSelect/LanguageSelect.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;;;EAGE,sBAAsB;EACtB,oBAAoB;EACpB,wCAAwC;AAC1C;;AAEA;EACE,6BAA6B;EAC7B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,WAAW;EACX,SAAS;EACT,6BAA6B;EAC7B,8BAA8B;AAChC;AACA;EACE,gBAAgB;AAClB;AACA;EACE,yBAAyB;EACzB,2CAA2C;AAC7C;AACA;EACE,aAAa;AACf;AACA;EACE,iCAAiC;AACnC","sourcesContent":[".languageSelect .MuiSelect-icon {\r\n  display: block;\r\n}\r\n\r\n.languageSelect.MuiInputBase-root:before,\r\n.languageSelect.MuiInputBase-root:after,\r\n.languageSelect .MuiSelect-select:focus {\r\n  content: \"\" !important;\r\n  border: 0 !important;\r\n  background-color: transparent !important;\r\n}\r\n\r\n.languageSelect .MuiSelect-select {\r\n  padding-left: 20px !important;\r\n  font-size: 14px;\r\n  font-style: normal;\r\n  font-weight: 700;\r\n  line-height: normal;\r\n  letter-spacing: -0.14px;\r\n  display: flex;\r\n  align-items: center;\r\n  gap: 5px;\r\n  width: 40px;\r\n  border: 0;\r\n  text-align: center !important;\r\n  padding-right: 10px !important;\r\n}\r\n.languageSelect .flag {\r\n  padding-top: 2px;\r\n}\r\n.languageItem {\r\n  display: block !important;\r\n  border-bottom: 1px solid #e9ebf1 !important;\r\n}\r\n.languageItem .flag {\r\n  display: none;\r\n}\r\n.languageItem:last-child {\r\n  border-bottom: initial !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
