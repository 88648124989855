import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useStateIfMounted } from "use-state-if-mounted";
import { useTranslation } from "react-i18next";
import LoginContext from "../../store/login-context";
import { accountService } from "../../services/accountService";

import "./ProfileSidebar.css";

const ProfileSidebar = () => {
  const { t } = useTranslation();
  const { loginContext } = useContext(LoginContext);
  const [user, setUser] = useStateIfMounted(null);
  const [firmName, setFirmName] = useState(null);

  useEffect(() => {
    accountService.getAccount().then((response) => {
      setFirmName(response.firmName);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUser(loginContext.user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginContext.user]);

  const firstLetter = (fullName) => {
    const res = fullName.toLowerCase().split(" ");
    return res.map((name) => name[0].toUpperCase()).join("");
  };

  return user != null ? (
    <Box className="profile-widget">
      <div className="profile">
        <div className="left">
          <div>{firstLetter(user.fullName)}</div>
        </div>
        <div className="right">
          <span>{user.fullName}</span>
          <small>{firmName}</small>
        </div>
      </div>
      <div className="menu">
        <NavLink to="/profile" activeClassName="active">
          <span>{t("account_information")}</span>{" "}
          <i className="ri-arrow-drop-right-line" />
        </NavLink>

        <NavLink to="/previous-orders" activeClassName="active">
          <span>{t("my_order")}</span>{" "}
          <i className="ri-arrow-drop-right-line" />
        </NavLink>

        <NavLink to="/invoices" activeClassName="active">
          <span>{t("my_invoice")}</span>{" "}
          <i className="ri-arrow-drop-right-line" />
        </NavLink>

        <NavLink to="/payments" activeClassName="active">
          <span>{t("my_payments")}</span>{" "}
          <i className="ri-arrow-drop-right-line" />
        </NavLink>

        <NavLink to="/favourites" activeClassName="active">
          <span>{t("favorite_products")}</span>{" "}
          <i className="ri-arrow-drop-right-line" />
        </NavLink>

        <NavLink
          to="/logout"
          onClick={() => {
            loginContext.setLoginState("user", null);
          }}
        >
          <span>{t("logout")}</span> <i className="ri-arrow-drop-right-line" />
        </NavLink>
      </div>
    </Box>
  ) : (
    "Loading"
  );
};

export default ProfileSidebar;
