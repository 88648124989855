import "./Header.css";
import Logo from "../Logo/Logo";
import { config } from "../../../services/config";
import { NavLink } from "react-router-dom";
import Profile from "../../Control/Profile/Profile";
import { useTranslation } from "react-i18next";
import LanguageSelect from "../../LanguageSelect/LanguageSelect";

function Header(props) {
  let imageUrl = "/no-photo.png";
  if (props.logo != null) {
    imageUrl = config.baseOrigin + props.logo;
  }

  const { t } = useTranslation();

  return (
    <header className="mainHeader">
      <div className="header-container">
        <div className="appBar control-header">
          <div className="header-left">
            <div className="logo">
              <Logo imageUrl={imageUrl} />
            </div>
            <div className="nav-menu">
              <ul>
                <li>
                  <NavLink to="/apps/control/dashboard">
                    {t("orders")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/apps/control/waybills">
                    {t("waybills")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/apps/control/invoices">
                    {t("invoices")}
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="control-profile">
            <div className="language">
              <LanguageSelect />
            </div>
            <Profile mobile={false} />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
