import { baseService } from "../baseService.js";
import { config } from "../config";

export const orderService = {
  getOrders,
  getOrderItem,
  createInvoice,
  createWayBill,
  approveProduct,
  changeOrderStatus,
  getOrderInfo,
  getInvoicePdf,
  getWayybilPdf,
  getOrderStatus,
  getWayybills,
  getInvoices,
  orderPdf
};


function getOrders(page, size, category) {
  const body = { page: page, size: size, statusId: category};

  return baseService.post(
    config.endpoints.stockAgentOrders,
    size > 0 ? body : null
  );
}

function getOrderItem(orderId = null) {
  const body = { orderId: orderId }; 

  return baseService.post(
    config.endpoints.stockAgentOrder,
    body
  );
}

function createInvoice(orderId) {
  const body = { orderId: orderId }; 

  return baseService.post(
    config.endpoints.stockAgentCreateInvoice,
    body
  );
}

function createWayBill(orderId) {
  const body = { orderId: orderId }; 

  return baseService.post(
    config.endpoints.stockAgentCreateWayBill,
    body
  );
}

function approveProduct(orderProductId, piece) {
  const body = { orderProductId: orderProductId, piece: piece}; 

  return baseService.post(
    config.endpoints.stockAgentApproveProduct,
    body
  );
}

function changeOrderStatus(orderId, status) {
  const body = { orderId: orderId, status: status}; 

  return baseService.post(
    config.endpoints.stockAgentChangeOrderStatus,
    body
  );
}

function getOrderInfo(orderId) {
  const body = { orderId: orderId}; 

  return baseService.post(
    config.endpoints.stockAgentOrderHeaderInfo,
    body
  );
}

function getInvoicePdf(invoiceId) {
  const body = {invoiceId : invoiceId}
  
  return baseService.post(
    config.endpoints.stockAgentInvoicePdf + '?invoiceId=' + invoiceId, 
    body
  );
}

function getOrderStatus() {
  return baseService.post(
    config.endpoints.stockAgentOrderStatus,
  );
}

function getWayybilPdf(waybillId) {
  return baseService.post(
    config.endpoints.stockAgentWayybillPdf + '?waybillId=' + waybillId
  );
}

function getWayybills(page, size) {
  const sort = [
    {
      "field": "waybillDate",
      "dir": "desc"
    }
  ];

  const body = { page: page, size: size, sort:sort};

  return baseService.post(
    config.endpoints.stockAgentWayybills,
    size > 0 ? body : null
  );
}

function getInvoices(page, size) {
  const sort = [
    {
      "field": "invoiceDate",
      "dir": "desc"
    }
  ];

  const body = { page: page, size: size, sort: sort};

  return baseService.post(
    config.endpoints.stockAgentInvoices,
    size > 0 ? body : null
  );
}


function orderPdf(id, status) {
  const body = { orderId: id, showImageOnPrint: false, locationSortAsc: true, approvedOnes: status};

  return baseService.post(config.endpoints.orderPrint, body);
}