import { MenuItem, Select } from "@mui/material";
import BasketCounter from "../BasketCounter/BasketCounter";
import { ExpandMore } from "@mui/icons-material";
import { useEffect, useState, useContext } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import useFormatHelper from "../../useFormatHelper";
import { useTranslation } from "react-i18next";
import BasketContext from "../../store/basket-context";

const MobilesaleUnitItem = (props) => {
  const { basketContext } = useContext(BasketContext);

  const { formatMoney } = useFormatHelper();
  const { t } = useTranslation();

  const product = props.product;
  const [selectedUnit, setSelectedUnit] = useState(0);
  const [salesUnit, setSalesUnits] = useState({});
  const [price, setPrice] = useState(0);
  const [unitPrice, setUnitPrice] = useState(0);
  const [count, setCount] = useStateIfMounted(0);

  const handleChange = (event) => {
    setSelectedUnit(event.target.value);
    setSalesUnits(product.salesUnits[event.target.value]);
    setPrice(product.salesUnits[event.target.value].price);
    setUnitPrice(product.salesUnits[event.target.value].unitPrice);
  };

  useEffect(() => {
    setSalesUnits(product.salesUnits[0]);
    setPrice(product.salesUnits[0].price);
    setUnitPrice(product.salesUnits[0].unitPrice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCount(basketContext.getItem(product.id, salesUnit.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basketContext, product.id, salesUnit.id]);

  return (
    <div className="mobile-sales-units">
      <div className="top">
        <div className="left">
          <Select
            IconComponent={ExpandMore}
            labelId="country"
            id="country"
            className="full-width"
            sx={{
              "& fieldset": {
                border: "0",
                color: "#151518",
                fontFamily: "Satoshi-Medium",
              },
            }}
            value={selectedUnit}
            onChange={handleChange}
          >
            {product.salesUnits.map((saleUnit, index) => (
              <MenuItem key={saleUnit.id} value={index}>
                {saleUnit.unitName} ({saleUnit.piece} {t("stk")})
              </MenuItem>
            ))}
          </Select>
          <span>Stück Preis: {formatMoney(unitPrice)}€</span>
        </div>
        <div className="right">{formatMoney(price)}€</div>
      </div>
      <div className="bottom">
        <BasketCounter
          readonly={false}
          productId={product.id}
          productName={product.name}
          productUnit={product.unit}
          imageUrl={product.image}
          salesUnitItemId={salesUnit.id}
          salesUnitItemUnitName={salesUnit.unitName}
          salesUnitItemPiece={salesUnit.piece}
          salesUnitItemUnitPrice={salesUnit.unitPrice}
          price={salesUnit.price}
          count={count}
        />
      </div>
    </div>
  );
};

export default MobilesaleUnitItem;
