// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-profile {
  border-radius: 8px;
  border: 1px solid #e9ebf1;
}
.my-profile .profile-title {
  color: #151518;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.24px;
  padding: 15px;
}
.form-body .form-row {
  display: flex;
  padding: 5px 15px;
}
.form-body .form-row .form-item {
  width: 100%;
}
.form-body .form-row .form-item input {
  border: 0 !important;
}
.full-width {
  width: 100%;
}
.form-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}
.form-btn .form-button {
  border-radius: 4px;
  background: #e5322d;
  color: #fff;
  border: 0;
  width: 180px;
  height: 45px;
  margin: 15px;
}
.form-btn .form-button:hover {
  color: #000;
}
.form-body .form-title {
  border: 1px solid #e9ebf1;
  background: #f9fafb;
  color: #000;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.18px;
  padding: 15px;
}
.form-body .MuiFormLabel-root {
  color: #151518;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.18px;
  margin-bottom: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/MyProfile/MyProfile.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,yBAAyB;AAC3B;AACA;EACE,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;AACf;AACA;EACE,aAAa;EACb,iBAAiB;AACnB;AACA;EACE,WAAW;AACb;AACA;EACE,oBAAoB;AACtB;AACA;EACE,WAAW;AACb;AACA;EACE,aAAa;EACb,yBAAyB;EACzB,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,WAAW;EACX,SAAS;EACT,YAAY;EACZ,YAAY;EACZ,YAAY;AACd;AACA;EACE,WAAW;AACb;AACA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;AACf;AACA;EACE,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;AACpB","sourcesContent":[".my-profile {\r\n  border-radius: 8px;\r\n  border: 1px solid #e9ebf1;\r\n}\r\n.my-profile .profile-title {\r\n  color: #151518;\r\n  font-size: 20px;\r\n  font-style: normal;\r\n  line-height: normal;\r\n  letter-spacing: -0.24px;\r\n  padding: 15px;\r\n}\r\n.form-body .form-row {\r\n  display: flex;\r\n  padding: 5px 15px;\r\n}\r\n.form-body .form-row .form-item {\r\n  width: 100%;\r\n}\r\n.form-body .form-row .form-item input {\r\n  border: 0 !important;\r\n}\r\n.full-width {\r\n  width: 100%;\r\n}\r\n.form-btn {\r\n  display: flex;\r\n  justify-content: flex-end;\r\n  margin-top: 15px;\r\n}\r\n.form-btn .form-button {\r\n  border-radius: 4px;\r\n  background: #e5322d;\r\n  color: #fff;\r\n  border: 0;\r\n  width: 180px;\r\n  height: 45px;\r\n  margin: 15px;\r\n}\r\n.form-btn .form-button:hover {\r\n  color: #000;\r\n}\r\n.form-body .form-title {\r\n  border: 1px solid #e9ebf1;\r\n  background: #f9fafb;\r\n  color: #000;\r\n  font-size: 16px;\r\n  font-style: normal;\r\n  line-height: normal;\r\n  letter-spacing: -0.18px;\r\n  padding: 15px;\r\n}\r\n.form-body .MuiFormLabel-root {\r\n  color: #151518;\r\n  font-size: 15px;\r\n  font-style: normal;\r\n  font-weight: 400;\r\n  line-height: normal;\r\n  letter-spacing: -0.18px;\r\n  margin-bottom: 5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
