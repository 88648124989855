import { useState } from "react";
import { config } from "../../../services/config";
import ProductItemDialog from "../ProductItemDialog/ProductItemDialog";
import useFormatHelper from "../../../useFormatHelper";

const ProductItem = (props) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const baseOrigin = config.baseOrigin;
    const defaultImg = "/no-photo.png";
    const orderItem = props.orderItem;

    const { formatMoney } = useFormatHelper();

    let menge = orderItem.numberOfItems - (orderItem.numberOfApprovedItems !== undefined ? orderItem.numberOfApprovedItems : 0)

    if(isNaN(menge)) {
        menge = 0;
    }

    if (menge === 0) {
        return <></>;
    }

    return (
        <>
            <tr 
                onClick={(e) => {
                    if (!e.target.closest('.approve-button')) {
                        props.approveProduct(orderItem, 1);
                    }
                }}
            >
                <th><img src={orderItem.productImage ? baseOrigin + orderItem.productImage : defaultImg} height={50} alt={orderItem.productName} /></th>
                <th className="bold-text">{orderItem.productName}</th>
                <th>{menge}</th>
                <th>{orderItem?.salesUnitName}</th>
                <th>{formatMoney(orderItem.price)}</th>
                <th>{formatMoney(orderItem.price * menge)}</th>
                <th className="approve-row">
                    <button 
                        className="approve-button"
                        onClick={(e) => {
                            e.stopPropagation();
                            setDialogOpen(true);
                        }}
                    >
                        Aktar
                    </button>
                </th>
            </tr>
            <ProductItemDialog
                dialogOpen={dialogOpen}
                approveProduct={props.approveProduct}
                setDialogOpen={setDialogOpen}
                orderItem={orderItem}
                setAutoFocus={props.setAutoFocus}
            />
        </>
    );
}

export default ProductItem;