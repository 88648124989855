import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useStateIfMounted } from "use-state-if-mounted";
import BasketProductItem from "../../components/BasketProductItem/BasketProductItem";
import { orderService } from "../../services/orderService";
import LoginContext from "../../store/login-context";
import useFormatHelper from "../../useFormatHelper";
import ProfileSidebar from "../../components/ProfileSidebar/ProfileSidebar";
import "./PreviousOrderDetail.css";
import { config } from "../../services/config";

function PreviousOrderDetail() {
  let { orderId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { formatMoney, formatDate, textTrim } = useFormatHelper();

  const { loginContext } = useContext(LoginContext);
  const [orderProducts, setOrderProducuts] = useStateIfMounted([]);
  const [taxAmounts, setTaxAmounts] = useStateIfMounted();
  const [totalAmount, setTotalAmount] = useStateIfMounted();
  const [netAmount, setNetAmount] = useStateIfMounted();
  const [orderDate, setOrderDate] = useStateIfMounted();
  const [orderStatus, setOrderStatus] = useStateIfMounted();
  const [invoce, setInvoice] = useStateIfMounted();

  useEffect(() => {
    orderService.getOrderItems(orderId).then((response) => {
      if (response.orderProducts != null) {
        const items = response.orderProducts.map((m) => {
          return {
            productId: m.productId,
            productName: m.name,
            productUnit: m.productUnit,
            imageUrl: m.image,
            salesUnitItemId: m.salesUnitId,
            salesUnitItemUnitName: m.salesUnitName,
            salesUnitItemPiece: m.salesUnitPiece,
            salesUnitItemUnitPrice: m.salesUnitUnitPrice,
            count: m.piece,
            price: m.salesUnitPrice,
            defaultPrice: m.defaultPrice,
            defaultUnitPrice: m.defaultUnitPrice,
          };
        });
        setOrderProducuts(items);
      }
      setNetAmount(response.netAmount);
      setTaxAmounts(response.taxAmounts);
      setTotalAmount(response.totalAmount);
      setOrderDate(response.orderDate);
      setOrderStatus(response.statusDescription);
      setInvoice(response.invoiceURL);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loginContext.user) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginContext.user]);

  let invoceUrl = config.baseOrigin + invoce;

  return (
    <>
      {orderStatus != null ? (
        <Container>
          <Box sx={{ mb: 0, mt: 0 }} md={{ mb: 4, mt: 4 }}>
            <Grid
              container
              columns={{ xs: 1, md: 7 }}
              spacing={3}
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Grid item xs={1} md={2}>
                <Box>
                  <ProfileSidebar />
                </Box>
              </Grid>
              <Grid item xs={1} md={5}>
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                    border: "1px solid #E9EBF1",
                  }}
                >
                  <div className="previous-title">
                    <div className="left">
                      {t("order-detail")}: #{orderId}
                    </div>
                    <div className={textTrim(orderStatus) + " status btx"}>
                      {orderStatus}
                    </div>
                  </div>
                  <div className="previous-bottom-title">
                    <div className="left">
                      {t("order-date")}: {formatDate(orderDate)}
                    </div>
                    {invoce && (
                      <div className="right">
                        <a href={invoceUrl}>
                          <div className="btx invoice">
                            <i className="ri-file-list-3-line" />
                            {t("invoice")}
                          </div>
                        </a>
                      </div>
                    )}
                  </div>
                  <Grid container xs={12} md={12}>
                    <Grid item xs={12} md={8} className="order-body">
                      <div className="item-in-cart">{t("items_in_cart")}</div>
                      <div className="basket-page sidebar-basket">
                        {orderProducts !== null &&
                          orderProducts.length > 0 &&
                          orderProducts.map((orderProduct, index) => {
                            return (
                              <BasketProductItem
                                limit={35}
                                key={`basket_item_${orderProduct.productId}_${orderProduct.saleUnitId}_${index}`}
                                basketItem={orderProduct}
                                readonly={true}
                                first={index === 0}
                                last={orderProducts.length === index + 1}
                                previousOrder={true}
                              />
                            );
                          })}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={4} className="order-body">
                      <div className="item-in-cart summary">
                        {t("order_summary")}
                      </div>
                      <div className="basket-page-total total">
                        {taxAmounts != null && taxAmounts.length > 0 && (
                          <>
                            <Box
                              sx={{
                                width: "100%",
                              }}
                            >
                              <Box className="total-item">
                                <Typography variant="axt">
                                  {t("my_basket_total")}
                                </Typography>
                                <Typography variant="bxt">
                                  €{formatMoney(netAmount)}
                                </Typography>
                              </Box>
                              <Box className="total-item">
                                <Typography variant="axt">
                                  {t("total_tax")}
                                </Typography>
                                <Typography variant="bxt">
                                  € {formatMoney(totalAmount - netAmount)}
                                </Typography>
                              </Box>
                              <Box className="total-item">
                                <Typography variant="axt">
                                  {t("total_price")}
                                </Typography>
                                <Typography variant="bxt">
                                  € {formatMoney(totalAmount)}
                                </Typography>
                              </Box>
                            </Box>
                          </>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      ) : (
        ""
      )}
    </>
  );
}

export default PreviousOrderDetail;
