import { MenuItem, Pagination, PaginationItem, Select } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import "./ControlWaybill.css";
import { orderService } from '../../../services/control/orderService';
import { useStateIfMounted } from 'use-state-if-mounted';
import useFormatHelper from '../../../useFormatHelper';
import { useSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ControlWaybill = () => {
  const { formatDateTime, formatMoney } = useFormatHelper();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const [perPage, setPerPage] = useState(20);
  const [waybills, setWaybills] = useStateIfMounted([]);
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const iframeRef = useRef(null);
  const [active, setActive] = useState();

  useEffect(() => {
    orderService.getWayybills(page, perPage).then((response) => {
      setWaybills(response.content);
      setTotalPage(response.totalPages);

      if(searchParams.get('id') != undefined){
        getWaybillPDf(searchParams.get('id'));
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perPage, page]);

  const setCurrentPage = (e) => {
    if (e.target.className === "ri-arrow-left-double-line") {
      setPage(0);
    } else if (e.target.className === "ri-arrow-left-s-line") {
      setPage(page - 1);
    } else if (e.target.className === "ri-arrow-right-s-line") {
      setPage(page + 1);
    } else if (e.target.className === "ri-arrow-right-double-line") {
      setPage(totalPage - 1);
    } else {
      setPage(e.target.textContent - 1);
    }
  };

  const productCount = (event) => {
    setPerPage(event.target.value);
  };

  const getWaybillPDf = (wayybillId) => {
    orderService
      .getWayybilPdf(wayybillId)
      .then((response) => {
        var byteCharacters = atob(response.data);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([byteArray], { type: 'application/pdf' });

        var blobUrl = URL.createObjectURL(blob);

        if (iframeRef.current) {
          iframeRef.current.parentNode.removeChild(iframeRef.current);
        }

        var iframe = document.createElement('iframe');
        iframe.src = blobUrl;
        iframe.style.width = '100%';
        iframe.style.height = '100vh';
        document.getElementById('pdfContainer').appendChild(iframe);

        iframeRef.current = iframe;
        setActive(wayybillId);

      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: 'error',
        });
      });
  };

  return (
    <div className='header-container'>
      <div className='invoices'>
        <div className='invoice-head'>
          <div className='title'>{t('waybills')}</div>
          <div className="p-product-counter">
            <Select onChange={productCount} value={perPage}>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </div>
        </div>
        <div className='invoice-container'>
          <div className='invoice-left'>
            <div className='invoice-list'>
              {waybills !== null &&
                waybills.length > 0 &&
                waybills.map((wayybill, index) => {
                  return (<div className={`invoice-item ${wayybill.waybillId === active && ('active')}`} onClick={() => getWaybillPDf(wayybill.waybillId)}>
                    <div className='invoice-item-top'>
                      <div>
                        <div><span>{wayybill.customerName}</span>  </div>
                        <div> #{wayybill.orderId}  - ({formatDateTime(wayybill.waybillDate)})</div>
                        <div className='invoice-description'>
                          {wayybill.waybillStatusDescription}
                        </div>
                      </div>
                      <div className='invoice-price'> {formatMoney(wayybill.totalPrice)}€</div>
                    </div>
                  </div>)
                })}
            </div>
            <div className="pagination">
              <Pagination
                count={totalPage}
                onChange={setCurrentPage}
                renderItem={(item) => (
                  <PaginationItem
                    components={{
                      last: (props) => (
                        <i className="ri-arrow-right-double-line" />
                      ),
                      next: (props) => <i className="ri-arrow-right-s-line" />,
                      first: (props) => (
                        <i className="ri-arrow-left-double-line" />
                      ),
                      previous: (props) => (
                        <i className="ri-arrow-left-s-line" />
                      ),
                    }}
                    {...item}
                  />
                )}
                showFirstButton
                showLastButton
              />
            </div>
          </div>
          <div className='invoice-right'>
            <div id="pdfContainer">
              <span>{t('pdf_viewer')}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ControlWaybill