import { List } from "@mui/material";
import { useEffect } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import { categoryService } from "../../services/categoryService";
import SidebarItem from "./SidebarItem";

function Sidebar(props) {
  const [categories, setCategories] = useStateIfMounted([]);

  useEffect(() => {
    // const categoriesByStorage = sessionStorage.getItem("categories");
    // if (categoriesByStorage) {
    //   setCategories(JSON.parse(categoriesByStorage));
    // } else {
    categoryService.getCategories().then((response) => {
      sessionStorage.setItem("categories", JSON.stringify(response));
      setCategories(response);
    });
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="m-sidebar">
      <List>
        {categories.map((category) => (
          <SidebarItem
            key={"sidebar_" + category.id}
            category={category}
            fontWeight="600"
          />
        ))}
      </List>
    </div>
  );
}

export default Sidebar;
