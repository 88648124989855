import { Box, Grid, Container } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ProductContext from "../../store/product-context";
import ProfileSidebar from "../../components/ProfileSidebar/ProfileSidebar";
import "./Favourites.css";
import ProductItem from "../../components/ProductItem/ProductItem";
import { productService } from "../../services/productService";

let handleScroll;

function Favourites() {
  const { productContext } = useContext(ProductContext);
  const [products, setProducts] = useState([]);
  const { t } = useTranslation();

  const fetchProducts = useCallback(() => {
    window.removeEventListener("scroll", handleScroll, true);

    handleScroll = () => {
      const element = window.document.getElementById("productGrid");
      if (!element) return;
      let userScrollHeight = window.innerHeight + window.scrollY + 600;
      let windowBottomHeight = element.getBoundingClientRect().height;
      if (userScrollHeight >= windowBottomHeight) {
        window.removeEventListener("scroll", handleScroll, true);
        productContext.setProductState(
          "page",
          productContext.getProductState("page") + 1
        );
      }
    };

    const page = productContext.getProductState("page");
    if (page === undefined) {
      productContext.setProductState("page", 0);
      return;
    }
    const body = { page: page, size: 50, favorite: true };
    const controller = new AbortController();
    const signal = controller.signal;

    productService.getProducts(body, { signal }).then((response) => {
      if (page === 0) {
        setProducts([]);
      }
      setProducts((p) => [...p, ...response.content]);
      if (!response.last) {
        window.addEventListener("scroll", handleScroll, true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productContext]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  return (
    <Container>
      <Box sx={{ mb: 0, mt: 0 }} md={{ mb: 4, mt: 4 }}>
        <Grid
          container
          columns={{ xs: 1, md: 7 }}
          spacing={3}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Grid item xs={1} md={2}>
            <Box>
              <ProfileSidebar />
            </Box>
          </Grid>
          <Grid item xs={1} md={5}>
            <div className="big-title">
              <div className="head">{t("favorite_products")}</div>
            </div>
            <div className="home-grid" sx={{ mb: 2, mt: 2 }}>
              {products.map((product) => {
                return (
                  <div
                    className="home-grid-item"
                    key={"productItem_subCategory_grid" + product.id}
                  >
                    <ProductItem
                      key={"productItem_subCategory_" + product.id}
                      product={product}
                    />
                  </div>
                );
              })}
            </div>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default Favourites;
