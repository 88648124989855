// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.control-dialog-title-red {
    background-color: #e5322d;
}`, "",{"version":3,"sources":["webpack://./src/components/Control/BarcodeItemDialog/BarcodeItemDialog.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B","sourcesContent":[".control-dialog-title-red {\r\n    background-color: #e5322d;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
