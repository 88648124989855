import { useState } from "react";
import { config } from "../../../services/config";
import useFormatHelper from "../../../useFormatHelper";
import UnApprovedDialog from "../UnApprovedDialog/UnApprovedDialog";

const ProductIApprovedtem = (props) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const baseOrigin = config.baseOrigin;
    const defaultImg = "/no-photo.png";
    const orderItem = props.orderItem;

    const { formatMoney } = useFormatHelper();

    let menge = orderItem.numberOfApprovedItems != undefined ? orderItem.numberOfApprovedItems : 0;
    if (menge === 0) {
        return <></>;
    }

    return (
        <>
            <tr
                onClick={() => {
                    setDialogOpen(true);
                }}
            >
                <th><img src={orderItem.productImage ? baseOrigin + orderItem.productImage : defaultImg} height={50} /></th>
                <th>{orderItem.productName}</th>
                <th>{menge}</th>
                <th>{orderItem?.salesUnitName}</th>
                <th>{formatMoney(orderItem.price)}</th>
                <th>{formatMoney(orderItem.price * menge)}</th>
            </tr>
            <UnApprovedDialog
                dialogOpen={dialogOpen}
                unApproveProduct={props.unApproveProduct}
                setDialogOpen={setDialogOpen}
                orderItem={orderItem}
                setAutoFocus={props.setAutoFocus}
            />
        </>
    );
}

export default ProductIApprovedtem;