// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  background-color: #fff;
  font-family: "Satoshi-Medium";
}
#root {
  height: 100%;
}
*,
::before,
::after {
  box-sizing: border-box;
  line-height: 1.43;
  letter-spacing: 0.01071em;
}
*:not(
    path,
    .MuiIconButton-root,
    svg.MuiSvgIcon-root,
    .MuiListItemIcon-root,
    .MuiTypography-root
  ) {
  font-size: 14px;
}

.MuiBackdrop-root.css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
  backdrop-filter: blur(1px);
  background-color: rgba(24, 17, 45, 0.72);
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,sBAAsB;EACtB,6BAA6B;AAC/B;AACA;EACE,YAAY;AACd;AACA;;;EAGE,sBAAsB;EACtB,iBAAiB;EACjB,yBAAyB;AAC3B;AACA;;;;;;;EAOE,eAAe;AACjB;;AAEA;EACE,0BAA0B;EAC1B,wCAAwC;AAC1C","sourcesContent":["body {\r\n  margin: 0;\r\n  background-color: #fff;\r\n  font-family: \"Satoshi-Medium\";\r\n}\r\n#root {\r\n  height: 100%;\r\n}\r\n*,\r\n::before,\r\n::after {\r\n  box-sizing: border-box;\r\n  line-height: 1.43;\r\n  letter-spacing: 0.01071em;\r\n}\r\n*:not(\r\n    path,\r\n    .MuiIconButton-root,\r\n    svg.MuiSvgIcon-root,\r\n    .MuiListItemIcon-root,\r\n    .MuiTypography-root\r\n  ) {\r\n  font-size: 14px;\r\n}\r\n\r\n.MuiBackdrop-root.css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {\r\n  backdrop-filter: blur(1px);\r\n  background-color: rgba(24, 17, 45, 0.72);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
