import { ShoppingBasket } from "@mui/icons-material";
import {
  Box,
  Grid,
  Container,
  Typography,
  MenuItem,
  Select,
  Pagination,
  PaginationItem,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useStateIfMounted } from "use-state-if-mounted";
import { orderService } from "../../services/orderService";
import LoginContext from "../../store/login-context";
import ProductContext from "../../store/product-context";
import ProfileSidebar from "../../components/ProfileSidebar/ProfileSidebar";
import "./Invoice.css";
import InvoiceItem from "../../components/InvoiceItem/InvoiceItem";
import useFormatHelper from "../../useFormatHelper";

function Invoices() {
  const { loginContext } = useContext(LoginContext);
  const { productContext } = useContext(ProductContext);

  const { formatMoney } = useFormatHelper();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [orders, setOrders] = useStateIfMounted([]);
  const [perPage, setPerPage] = useState(20);
  const [paged, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [openAmounts, setOpenAmounts] = useState({});

  const setCurrentPage = (e) => {
    if (e.target.className === "ri-arrow-left-double-line") {
      setPage(0);
    } else if (e.target.className === "ri-arrow-left-s-line") {
      setPage(paged - 1);
    } else if (e.target.className === "ri-arrow-right-s-line") {
      setPage(paged + 1);
    } else if (e.target.className === "ri-arrow-right-double-line") {
      setPage(totalPage - 1);
    } else {
      setPage(e.target.textContent - 1);
    }
  };

  const productCount = (event) => {
    setPerPage(event.target.value);
  };

  useEffect(() => {
    orderService.getOpenAmounts(false, false, true).then((response) => {
      setOpenAmounts(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    productContext.clearState();
    orderService.getInvoices(paged, perPage).then((response) => {
      setOrders(response.content);
      setTotalPage(response.totalPages);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perPage, paged]);

  useEffect(() => {
    if (!loginContext.user) {
      productContext.clearState();
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginContext.user]);

  return (
    <Container>
      <Box sx={{ mb: 0, mt: 0 }} md={{ mb: 4, mt: 4 }}>
        <Grid
          container
          columns={{ xs: 1, md: 7 }}
          spacing={3}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Grid item xs={1} md={2}>
            <Box>
              <ProfileSidebar />
            </Box>
          </Grid>
          <Grid item xs={1} md={5}>
            <div className="big-title">
              <div className="head">{t("my_invoice")}</div>
              <div className="p-product-counter">
                <Select onChange={productCount} value={perPage}>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </div>
            </div>

            <Box className="alerts-bar">
              {t("open_invoices")}:{" "}
              {formatMoney(openAmounts?.openInvoiceAmount)}€
            </Box>

            <Box>
              {orders !== null &&
                orders.length > 0 &&
                orders.map((orderItem, index) => {
                  return (
                    <InvoiceItem
                      key={`order_item_${orderItem.id}`}
                      orderItem={orderItem}
                      first={index === 0}
                      last={orders.length === index + 1}
                    />
                  );
                })}

              {(orders === null || orders.length === 0) && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box>
                    <ShoppingBasket
                      color="primary"
                      sx={{ fontSize: "250px", opacity: "0.5" }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h6" component="div" color="primary">
                      {t("my_previous_order_empty")}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      component="div"
                      color="text.secondary"
                    >
                      {t("my_previous_order_empty_2")}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>

            <div className="pagination">
              <Pagination
                count={totalPage}
                onChange={setCurrentPage}
                renderItem={(item) => (
                  <PaginationItem
                    components={{
                      last: (props) => (
                        <i className="ri-arrow-right-double-line" />
                      ),
                      next: (props) => <i className="ri-arrow-right-s-line" />,
                      first: (props) => (
                        <i className="ri-arrow-left-double-line" />
                      ),
                      previous: (props) => (
                        <i className="ri-arrow-left-s-line" />
                      ),
                    }}
                    {...item}
                  />
                )}
                showFirstButton
                showLastButton
              />
            </div>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default Invoices;
