import {
  Box,
  Grid,
  Container,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LoginContext from "../../store/login-context";
import ProfileSidebar from "../../components/ProfileSidebar/ProfileSidebar";
import "./MyProfile.css";
import { accountService } from "../../services/accountService";

function MyProfile() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { loginContext } = useContext(LoginContext);

  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [zipCode, setZipcode] = useState("");
  const [name, setName] = useState("");
  const [firmName, setFirmname] = useState("");
  const [taxNumber, setTaxNumber] = useState("");

  useEffect(() => {
    accountService.getAccount().then((response) => {
      setAddress(response.address);
      setZipcode(response.zipCode);
      setCity(response.city);
      setCountry(response.country);
      setName(response.name);
      setFirmname(response.firmName);
      setTaxNumber(response.taxNumber);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loginContext.user) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginContext.user]);

  return (
    <Container>
      <Box sx={{ mb: 0, mt: 0 }} md={{ mb: 4, mt: 4 }}>
        <Grid
          container
          columns={{ xs: 1, md: 7 }}
          spacing={3}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Grid item xs={1} md={2}>
            <Box>
              <ProfileSidebar />
            </Box>
          </Grid>
          <Grid item xs={1} md={5}>
            <Box className="my-profile">
              <div className="profile-title">{t("account_information")}</div>
              <form className="form-body">
                <div className="form-title">{t("contact_information")}</div>
                <div className="form-row">
                  <div className="form-item">
                    <InputLabel id="full-name">{t("fullName")}</InputLabel>
                    <TextField
                      id="full-name"
                      className="full-width"
                      sx={{
                        "& fieldset": { border: "1px solid #E9EBF1" },
                      }}
                      value={name}
                      disabled
                    />
                  </div>
                  <div className="form-item">
                    <InputLabel id="username">{t("company_name")}</InputLabel>
                    <TextField
                      id="username"
                      className="full-width"
                      sx={{
                        "& fieldset": { border: "1px solid #E9EBF1" },
                      }}
                      value={firmName}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-row second">
                  <div className="form-item">
                    <InputLabel id="email">{t("taxNumber")}</InputLabel>
                    <TextField
                      id="email"
                      className="full-width"
                      sx={{
                        "& fieldset": { border: "1px solid #E9EBF1" },
                      }}
                      value={taxNumber}
                      disabled
                    />
                  </div>
                  {
                    //   <div className="form-item">
                    //   <InputLabel id="phone">Phone Number</InputLabel>
                    //   <TextField
                    //     id="phone"
                    //     className="full-width"
                    //     sx={{
                    //       "& fieldset": { border: "1px solid #E9EBF1" },
                    //     }}
                    //   />
                    // </div>
                  }
                </div>
                <div className="form-title">{t("address_information")}</div>
                <div className="form-row">
                  <div className="form-item">
                    <InputLabel id="city">{t("city")}</InputLabel>
                    <TextField
                      id="city"
                      className="full-width"
                      sx={{
                        "& fieldset": { border: "1px solid #E9EBF1" },
                      }}
                      value={city}
                      disabled
                    />
                  </div>
                  <div className="form-item">
                    <InputLabel id="zip">{t("zipCode")}</InputLabel>
                    <TextField
                      id="zip"
                      className="full-width"
                      sx={{
                        "& fieldset": { border: "1px solid #E9EBF1" },
                      }}
                      value={zipCode}
                      disabled
                    />
                  </div>
                  <div className="form-item">
                    <InputLabel id="country">{t("country")}</InputLabel>
                    <Select
                      labelId="country"
                      id="country"
                      className="full-width"
                      value={1}
                      sx={{
                        "& fieldset": { border: "1px solid #E9EBF1" },
                      }}
                      disabled
                    >
                      <MenuItem value="1" selected>
                        {country}
                      </MenuItem>
                    </Select>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-item">
                    <InputLabel id="address">{t("address")}</InputLabel>
                    <TextField
                      id="address"
                      multiline
                      minRows={3}
                      className="full-width"
                      sx={{
                        "& fieldset": { border: "1px solid #E9EBF1" },
                      }}
                      value={address}
                      disabled
                    />
                  </div>
                </div>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default MyProfile;
