// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27%3E%3Cpath fill=%27none%27 d=%27M0 0h24v24H0z%27%3E%3C/path%3E%3Cpath d=%27M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z%27 fill=%27rgba%28229,50,45,1%29%27%3E%3C/path%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-title {
  color: #151518;
  font-size: 26px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.page-body {
  width: 100%;
  margin-top: 15px;
  font-family: "Satoshi-Regular";
}
.page-body p {
  color: #555a60;
  font-size: 15px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 10px;
}
.page-body h3 {
  color: #151518;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.page-body ul {
  padding-left: 20px;
  list-style: none;
}
.page-body ul li {
  position: relative;
}
.page-body ul li:before {
  height: 15px;
  width: 15px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  position: absolute;
  content: "";
  left: -20px;
  top: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Page/Page.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,0BAA0B;EAC1B,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,gBAAgB;EAChB,8BAA8B;AAChC;AACA;EACE,cAAc;EACd,0BAA0B;EAC1B,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,oBAAoB;AACtB;AACA;EACE,cAAc;EACd,0BAA0B;EAC1B,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,WAAW;EACX,yDAA8b;EAC9b,kBAAkB;EAClB,WAAW;EACX,WAAW;EACX,QAAQ;AACV","sourcesContent":[".page-title {\r\n  color: #151518;\r\n  font-size: 26px !important;\r\n  font-style: normal;\r\n  font-weight: 700;\r\n  line-height: normal;\r\n}\r\n.page-body {\r\n  width: 100%;\r\n  margin-top: 15px;\r\n  font-family: \"Satoshi-Regular\";\r\n}\r\n.page-body p {\r\n  color: #555a60;\r\n  font-size: 15px !important;\r\n  font-style: normal;\r\n  font-weight: 500;\r\n  line-height: normal;\r\n  padding-bottom: 10px;\r\n}\r\n.page-body h3 {\r\n  color: #151518;\r\n  font-size: 18px !important;\r\n  font-style: normal;\r\n  font-weight: 700;\r\n  line-height: normal;\r\n}\r\n.page-body ul {\r\n  padding-left: 20px;\r\n  list-style: none;\r\n}\r\n.page-body ul li {\r\n  position: relative;\r\n}\r\n.page-body ul li:before {\r\n  height: 15px;\r\n  width: 15px;\r\n  background-image: url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'%3E%3C/path%3E%3Cpath d='M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z' fill='rgba(229,50,45,1)'%3E%3C/path%3E%3C/svg%3E\");\r\n  position: absolute;\r\n  content: \"\";\r\n  left: -20px;\r\n  top: 2px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
