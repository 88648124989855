// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.control-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.control-header {
  padding-right: 30px;
}
.control-header .header-left {
  display: flex;
  align-items: center;
}
.control-header .nav-menu ul {
  display: flex;
  gap: 30px;
}
.control-header .nav-menu ul li {
  list-style-type: none;
}
.control-header .nav-menu ul li a {
  font-family: "Satoshi-Regular";
  text-transform: uppercase;
  font-size: 17px;
  text-decoration: none;
  padding: 10px;
  color:#333;
}
.control-header .control-profile span {
  margin-left: 5px;
}
.control-header ul .active {
  background-color: transparent !important;
  color: #fff !important;
  font-weight: bold !important;
  background: #e5322d !important;
}

.control-profile .language span{
  margin-left:0px;
}

.control-profile .language {
  height: auto;
  margin-right: 5px;
}

.control-profile .language .languageSelect {
  height: 45px;
}`, "",{"version":3,"sources":["webpack://./src/components/Control/Header/Header.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,SAAS;AACX;AACA;EACE,qBAAqB;AACvB;AACA;EACE,8BAA8B;EAC9B,yBAAyB;EACzB,eAAe;EACf,qBAAqB;EACrB,aAAa;EACb,UAAU;AACZ;AACA;EACE,gBAAgB;AAClB;AACA;EACE,wCAAwC;EACxC,sBAAsB;EACtB,4BAA4B;EAC5B,8BAA8B;AAChC;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".control-header {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n}\r\n.control-header {\r\n  padding-right: 30px;\r\n}\r\n.control-header .header-left {\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n.control-header .nav-menu ul {\r\n  display: flex;\r\n  gap: 30px;\r\n}\r\n.control-header .nav-menu ul li {\r\n  list-style-type: none;\r\n}\r\n.control-header .nav-menu ul li a {\r\n  font-family: \"Satoshi-Regular\";\r\n  text-transform: uppercase;\r\n  font-size: 17px;\r\n  text-decoration: none;\r\n  padding: 10px;\r\n  color:#333;\r\n}\r\n.control-header .control-profile span {\r\n  margin-left: 5px;\r\n}\r\n.control-header ul .active {\r\n  background-color: transparent !important;\r\n  color: #fff !important;\r\n  font-weight: bold !important;\r\n  background: #e5322d !important;\r\n}\r\n\r\n.control-profile .language span{\r\n  margin-left:0px;\r\n}\r\n\r\n.control-profile .language {\r\n  height: auto;\r\n  margin-right: 5px;\r\n}\r\n\r\n.control-profile .language .languageSelect {\r\n  height: 45px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
