import { CardActions } from "@mui/material";
import React, { useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import BasketContext from "../../store/basket-context";
import { useStateIfMounted } from "use-state-if-mounted";
import useFormatHelper from "../../useFormatHelper";
import BasketCounter from "../BasketCounter/BasketCounter";
import "./ProductBottom.css";

function ProductBottom(props) {
  const { t } = useTranslation();
  const { formatMoney } = useFormatHelper();
  const { basketContext } = useContext(BasketContext);
  const [count, setCount] = useStateIfMounted(0);
  const product = props.product;

  const productId = product.id;
  const productName = product.name;
  const productUnit = product.unit;
  const imageUrl = product.image;
  const salesUnitItemId = product.salesUnit.id;
  const salesUnitItemUnitName = product.salesUnit.unitName;
  const salesUnitItemPiece = product.salesUnit.piece;
  const salesUnitItemUnitPrice = product.salesUnit.unitPrice;
  let price = props.price;
  const green = props.green === "green" ? "green-plus" : "";

  useEffect(() => {
    setCount(props.count);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCount(basketContext.getItem(productId, salesUnitItemId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basketContext, productId, salesUnitItemId]);

  let unitPrice = product.discount?.price
    ? product.discount?.price
    : product.salesUnit.unitPrice;

  return (
    <>
      <div className="card-bottom">
        <div className="left">
          <div>{t("unit_price")}: </div>
          {product.salesUnit.piece > 1 && (
            <>
              <div>
                {product.salesUnit.unitName} ({product.salesUnit.piece})
              </div>
              {count === 0 && (
                <div className={product.discount && "text-discount"}>
                  {t("price")}:{" "}
                  {formatMoney(product.salesUnit.piece * unitPrice)}€
                </div>
              )}
            </>
          )}
        </div>
        <div className="right">
          <div className={count !== 0 ? "prices p-top" : "prices"}>
            {product.discount && price !== "-," ? (
              <div className="old">
                {formatMoney(product.salesUnit.unitPrice)}€
              </div>
            ) : (
              <div>&nbsp;</div>
            )}
            <div
              className={
                product.discount && price !== "-," ? "new text-discount" : "new"
              }
            >
              {unitPrice === undefined || price === "-,"
                ? "-,"
                : formatMoney(unitPrice)}{" "}
              €
            </div>
            {count === 0 && (
              <div className="add-container">
                <div
                  className={
                    product.discount
                      ? `add xx-add xx-bg-discount ${green}`
                      : `add xx-add ${green}`
                  }
                  onClick={() => {
                    setCount(count + 1);
                    basketContext.setItem(
                      product.tax?.value,
                      productId,
                      productName,
                      productUnit,
                      imageUrl,
                      salesUnitItemId,
                      salesUnitItemUnitName,
                      salesUnitItemPiece,
                      salesUnitItemUnitPrice,
                      price,
                      count + 1
                    );
                  }}
                >
                  <i
                    type="button"
                    className={
                      product.discount
                        ? "ri-add-line bg-discount"
                        : "ri-add-line"
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="cc">
        {count !== 0 && (
          <CardActions disableSpacing sx={{ justifyContent: "center" }}>
            <BasketCounter
              readonly={false}
              productId={productId}
              productName={productName}
              productUnit={productUnit}
              imageUrl={imageUrl}
              salesUnitItemId={salesUnitItemId}
              salesUnitItemUnitName={salesUnitItemUnitName}
              salesUnitItemPiece={salesUnitItemPiece}
              salesUnitItemUnitPrice={salesUnitItemUnitPrice}
              price={price}
              count={count}
              list={true}
              discount={product.discount}
              fullWidth={true}
              tax={product.tax?.value}
            />
          </CardActions>
        )}
      </div>
    </>
  );
}

export default ProductBottom;
