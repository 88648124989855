// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-page {
  background-color: #e9ebf1;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-header {
  color: #000;
  text-transform: uppercase;
  font-size: 25px !important;
  letter-spacing: 5px;
  text-align: center;
  margin-bottom: 30px;
}

.login-body {
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);
  width: 500px;
  padding: 40px 30px 5px 30px;
  background-color: #fff;
  border-radius: 10px;
}

.login-body .form-row {
  display: block;
  margin-bottom: 20px;
}

.login-body .form-row input {
  width: 100%;
  display: block;
  border: 1px solid #e9ebf1;
  padding: 10px 15px;
  border-radius: 5px;
}

.login-body .form-row label {
  width: 100%;
  display: block;
  color: #151518;
  font-family: "Satoshi-Medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.18px;
  padding-bottom: 15px;
}

.login-body .form-row .submit-btn {
  color: #fff;
  text-transform: uppercase;
  background-color: #e5322d !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Apps/Login/Login.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;EACX,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,0BAA0B;EAC1B,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,iDAAiD;EACjD,YAAY;EACZ,2BAA2B;EAC3B,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,cAAc;EACd,yBAAyB;EACzB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,cAAc;EACd,cAAc;EACd,6BAA6B;EAC7B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;EACvB,oBAAoB;AACtB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,oCAAoC;AACtC","sourcesContent":[".login-page {\r\n  background-color: #e9ebf1;\r\n  width: 100%;\r\n  height: 100vh;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n\r\n.login-header {\r\n  color: #000;\r\n  text-transform: uppercase;\r\n  font-size: 25px !important;\r\n  letter-spacing: 5px;\r\n  text-align: center;\r\n  margin-bottom: 30px;\r\n}\r\n\r\n.login-body {\r\n  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);\r\n  width: 500px;\r\n  padding: 40px 30px 5px 30px;\r\n  background-color: #fff;\r\n  border-radius: 10px;\r\n}\r\n\r\n.login-body .form-row {\r\n  display: block;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.login-body .form-row input {\r\n  width: 100%;\r\n  display: block;\r\n  border: 1px solid #e9ebf1;\r\n  padding: 10px 15px;\r\n  border-radius: 5px;\r\n}\r\n\r\n.login-body .form-row label {\r\n  width: 100%;\r\n  display: block;\r\n  color: #151518;\r\n  font-family: \"Satoshi-Medium\";\r\n  font-size: 16px;\r\n  font-style: normal;\r\n  font-weight: 700;\r\n  line-height: normal;\r\n  letter-spacing: -0.18px;\r\n  padding-bottom: 15px;\r\n}\r\n\r\n.login-body .form-row .submit-btn {\r\n  color: #fff;\r\n  text-transform: uppercase;\r\n  background-color: #e5322d !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
