import { AccountCircle, KeyboardArrowUp } from "@mui/icons-material";
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStateIfMounted } from "use-state-if-mounted";
import LoginContext from "../../../store/login-context";
import { useNavigate } from "react-router-dom";


const Profile = () => {

  const [user, setUser] = useStateIfMounted(null);
  const { loginContext } = useContext(LoginContext);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setUser(loginContext.user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginContext.user]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className="user-process-block">
      <div className="authentication-info">
        <div className="username">
          <Button
            sx={{
              color: "#fff",
              textTransform: "none",
            }}
            ref={anchorRef}
            id="composition-button"
            aria-controls={open ? "composition-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <AccountCircle />
            <Typography component="div" sx={{ m: 1, fontSize: "14px" }}>
              {user?.fullName}
            </Typography>
            {
              <KeyboardArrowUp
                className={open ? "profile-image open" : "profile-image"}
              />
            }
          </Button>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem
                        onClick={() => {
                          loginContext.setLoginState("user", null);
                          navigate("/apps/login");
                        }}
                      >
                        {t("logout")}
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
  </div>
  );
}

export default Profile;
