// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.control-dialog-top {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
}

.control-dialog-top button {
    background-color: #00c853;
    border:0;
    color:#fff;
    padding: 15px;
    text-transform: uppercase;
    font-size: 17px;
}
.control-dialog-bottom {
    margin-top:5px;
}
.control-dialog-bottom button {
    background-color: #e5322d;
    border:0;
    color:#fff;
    padding: 15px;
    width: 200px;
    margin-top: 25px;
    text-transform: uppercase;
    font-size: 17px;
}
.control-dialog-bottom button.close {
    background-color: #00c853;
}

.control-dialog-top .control-dialog-product-info{
    display: flex;
    align-items: center;
    justify-content: center;
}

.control-dialog-top span {
    font-weight: 600;
    font-size: 16px !important;
    display: block;
}
.yellow-bg {
    background-color: #ffa000 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Control/UnApprovedDialog/UnApprovedDialog.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,uBAAuB;AAC3B;;AAEA;IACI,yBAAyB;IACzB,QAAQ;IACR,UAAU;IACV,aAAa;IACb,yBAAyB;IACzB,eAAe;AACnB;AACA;IACI,cAAc;AAClB;AACA;IACI,yBAAyB;IACzB,QAAQ;IACR,UAAU;IACV,aAAa;IACb,YAAY;IACZ,gBAAgB;IAChB,yBAAyB;IACzB,eAAe;AACnB;AACA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;IAChB,0BAA0B;IAC1B,cAAc;AAClB;AACA;IACI,oCAAoC;AACxC","sourcesContent":[".control-dialog-top {\r\n    display: flex;\r\n    align-items: center;\r\n    gap: 20px;\r\n    justify-content: center;\r\n}\r\n\r\n.control-dialog-top button {\r\n    background-color: #00c853;\r\n    border:0;\r\n    color:#fff;\r\n    padding: 15px;\r\n    text-transform: uppercase;\r\n    font-size: 17px;\r\n}\r\n.control-dialog-bottom {\r\n    margin-top:5px;\r\n}\r\n.control-dialog-bottom button {\r\n    background-color: #e5322d;\r\n    border:0;\r\n    color:#fff;\r\n    padding: 15px;\r\n    width: 200px;\r\n    margin-top: 25px;\r\n    text-transform: uppercase;\r\n    font-size: 17px;\r\n}\r\n.control-dialog-bottom button.close {\r\n    background-color: #00c853;\r\n}\r\n\r\n.control-dialog-top .control-dialog-product-info{\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n\r\n.control-dialog-top span {\r\n    font-weight: 600;\r\n    font-size: 16px !important;\r\n    display: block;\r\n}\r\n.yellow-bg {\r\n    background-color: #ffa000 !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
