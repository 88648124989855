import { MenuItem, Select } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import "./LanguageSelect.css";

function LanguageSelect(props) {
  const { i18n } = useTranslation();
  let language = i18n.language;

  function changeLanguage(event) {
    sessionStorage.clear();
    i18n.changeLanguage(event.target.value);
    window.location.reload();
  }

  return (
    <Select
      className="languageSelect"
      value={language}
      onChange={changeLanguage}
      sx={{
        paddingRight: 0,
        boxShadow: "none",
        ".MuiOutlinedInput-notchedOutline": { border: 0 },
        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
          border: 0,
        },
        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            border: 0,
          },
      }}
      MenuProps={{
        PaperProps: {
          sx: {
            "& .MuiMenuItem-root": {
              fontSize: "12px",
              fontWeight: "700",
              padding: "0 10px",
              lineHeight: "35px",
              display: "block",
              textAlign: "center",
            },
            "&& .Mui-selected": {
              backgroundColor: "transparent",
            },
            ".MuiOutlinedInput-notchedOutline": { border: 0 },
          },
        },
      }}
    >
      <MenuItem value="en" className="languageItem">
        <span>EN</span>
      </MenuItem>
      <MenuItem value="de" className="languageItem">
        <span>DE</span>
      </MenuItem>
      <MenuItem value="tr" className="languageItem">
        <span>TR</span>
      </MenuItem>
    </Select>
  );
}

export default LanguageSelect;
