import { Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ControlDashboard.css";
import { orderService } from "../../../services/control/orderService";
import ControlItem from "./ControlItem";
import { useTranslation } from "react-i18next";

const ControlDashboard = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const STOCK_AGENT_NEW = 11;
  const STOCK_AGENT_PROCESSING = 12;
  const STOCK_AGENT_READY_FOR_DELIVERY = 13;
  const STOCK_AGENT_PARKED = 14;


  const [tabValue, setTabValue] = useState(0);
  const [orders, setOrders] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [category, setCategory] = useState(STOCK_AGENT_NEW);
  const [totalPage, setTotalPage] = useState(0);

  useEffect(() => {
    orderService.getOrders(page, perPage, category).then((response) => {
      setOrders(response.content);
      setTotalPage(response.totalPages);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  const handleTabChange = (event, newValue) => {
    let defaultCat = category;

    if(newValue == 0) {
      setCategory(STOCK_AGENT_NEW);
    }else if(newValue == 1) {
      setCategory(STOCK_AGENT_PROCESSING);
    }else if(newValue == 2) {
      setCategory(STOCK_AGENT_READY_FOR_DELIVERY);
    }else if(newValue == 3) {
      setCategory(STOCK_AGENT_PARKED);
    }
    setTabValue(newValue);
  };

  return (
    <div className="header-container">
      <div className="control-dashboard">
        <div className="content">
          <div className="tab-nav no-margin">
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              scrollButtons={false}
            >
              <Tab label={t("new")} className="control-tab" value={0} />
              <Tab label={t("processing")} value={1} />
              <Tab label={t("control")} value={2} />
              <Tab label={t("parked")} value={3} />
            </Tabs>
            <div className="tab-body">
              {orders !== null && orders.length > 0 ? (
                orders.map((orderItem) => {
                    return (
                      <ControlItem
                        orderItem={orderItem}
                      />
                    );
                  })
                ) : (
                  <p>{t('not_found')}</p>
                )}
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ControlDashboard;
