import { Box, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material"
import "./BarcodeItemDialog.css";
import { config } from "../../../services/config";
import { Close } from "@mui/icons-material";

const BarcodeItemDialog = (props) => {
  const orderItem = props.orderItem;
  const baseOrigin = config.baseOrigin;
  const defaultImg = "/no-photo.png";

  
  const handleClose = () => {
    props.setDialogOpen(false);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={props.dialogOpen}
      onClose={handleClose}
    >
      <DialogTitle className="control-dialog-title control-dialog-title-red">
        Bu Ürün Listede Bulunmuyor
      </DialogTitle>
      <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      <DialogContent dividers>
        <Box>
          <div className="control-dialog-product-info">
            <div className="control-image">
            <img
              src={defaultImg}
              style={{ width: '350px', height: '350px', objectFit: 'contain' }}
            />
            </div>
          </div>

        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default BarcodeItemDialog;