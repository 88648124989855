import { baseService } from "../baseService";
import { config } from "../config";

const login = (username, password) => {
  return baseService.post(config.endpoints.stockAgentLogin, { username, password });
}

export const authenticationService = {
  login
};
