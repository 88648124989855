import { baseService } from "./baseService";
import { config } from "./config";

export const companyService = {
  getCompanyInfo,
  getStoreURLs,
};

function getCompanyInfo() {
  return baseService.post(config.endpoints.getCompanyInfo);
}

function getStoreURLs() {
  return baseService.post(config.endpoints.getStoreURLs);
}
